import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import THEMES from '../js/styles/themes/app';

// Upload Modal Art
export const UploadArtWrapper = styled.div`
  background: ${(props) =>
    props.unsupportedFileType
      ? THEMES.THEME_RED
      : THEMES.COLORED_BACKGROUND_SECONDARY};
  height: ${(props) => props.height};
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  > svg {
    pointer-events: none;
  }
`;
export const ActionTitle = styled.h1`
  color: ${THEMES.COLORED_FOREGROUND_HIGH};
  font-size: 3rem;
  padding-top: 5rem;
  text-align: center;
`;

export const UploadArt = () => {
  const styledTheme = useContext(ThemeContext);

  return (
    <svg
      version="1.1"
      x="0px"
      y="0px"
      width="430px"
      height="146px"
      viewBox="0 0 430 146"
      overflow="visible"
      enableBackground="new 0 0 430 146"
    >
      <path
        fill={THEMES.COLORED_FOREGROUND_HIGH({ theme: styledTheme })}
        d="M54.937,108.955l1.778,5.166l5.176-1.782c1.429-0.491,2.189-2.036,1.695-3.473
  c-0.491-1.426-2.035-2.188-3.474-1.693L54.937,108.955z"
      />
      <path
        fill={THEMES.COLORED_FOREGROUND_HIGH({ theme: styledTheme })}
        d="M91.322,113.787l-6.24-18.125c-0.979-2.842-4.108-4.342-6.933-3.369l-44.001,15.15
  c-2.85,0.981-4.359,4.102-3.388,6.923l6.241,18.125c0.979,2.842,4.107,4.342,6.932,3.369l44-15.15
  C90.784,119.729,92.294,116.608,91.322,113.787z M49.914,128.021l-2.561,0.882c-2.873,0.99-5.984-0.534-6.971-3.396l-0.884-2.567
  l2.587-0.891l0.889,2.583c0.491,1.426,2.036,2.189,3.475,1.693l2.592-0.892c1.428-0.492,2.182-2.055,1.702-3.45l-4.456-12.94
  l2.587-0.891l4.442,12.901C54.312,123.944,52.777,127.036,49.914,128.021z M62.766,114.928l-5.16,1.777l2.668,7.75l-2.586,0.891
  l-4.448-12.917l-1.778-5.167l7.746-2.667c2.874-0.989,5.982,0.526,6.965,3.381C67.162,110.849,65.629,113.942,62.766,114.928z
  M78.393,118.216c-2.873,0.99-5.984-0.534-6.971-3.396l-2.658-7.719c-0.994-2.891,0.539-5.982,3.402-6.968l7.746-2.668l0.891,2.584
  l-7.76,2.672c-1.428,0.491-2.191,2.037-1.697,3.474l2.668,7.75c0.491,1.426,2.038,2.188,3.477,1.693l5.173-1.782l-1.778-5.166
  l-5.174,1.781l-0.889-2.584l7.759-2.672l3.559,10.334L78.393,118.216z"
      />
      <path
        fill={THEMES.COLORED_FOREGROUND_HIGH({ theme: styledTheme })}
        d="M32.137,76.35l5.334,15.493c0.493,1.431,2.059,2.189,3.456,1.708l12.956-4.461L32.137,76.35z"
      />
      <path
        fill={THEMES.COLORED_FOREGROUND_HIGH({ theme: styledTheme })}
        d="M147.963,31.727l0.587,4.182l-4.173,0.587c-1.16,0.162-2.219-0.644-2.381-1.798l-0.881-6.271
  c-0.164-1.162,0.641-2.222,1.793-2.384l6.26-0.88l-0.295-2.091l-6.249,0.879c-2.31,0.324-3.923,2.442-3.595,4.781l0.879,6.247
  c0.325,2.315,2.455,3.931,4.772,3.605l6.249-0.879l-1.175-8.362l-6.26,0.88l0.294,2.091L147.963,31.727z M128.596,30.186
  l7.729,9.573l2.086-0.294l-2.057-14.634l-2.086,0.293l1.469,10.453l-7.729-9.573l-2.087,0.293l2.057,14.635l2.087-0.294
  L128.596,30.186z M115.781,29.854l0.588,4.181l4.176-0.587c1.151-0.162,1.953-1.221,1.79-2.383
  c-0.162-1.154-1.218-1.961-2.378-1.798L115.781,29.854z M113.988,32.238l1.47,10.452l2.087-0.293l-0.882-6.271l4.163-0.585
  c2.31-0.325,3.922-2.444,3.596-4.77c-0.324-2.31-2.453-3.919-4.771-3.593l-6.249,0.878L113.988,32.238z M112.803,23.877
  l35.496-4.988c2.278-0.32,4.425,1.279,4.748,3.579l2.061,14.668c0.321,2.282-1.277,4.426-3.577,4.749l-35.495,4.988
  c-2.279,0.32-4.425-1.279-4.748-3.579l-2.062-14.667C108.904,26.344,110.504,24.2,112.803,23.877z M115.842,0l1.762,12.537
  c0.162,1.158,1.236,1.964,2.362,1.806l10.452-1.469L115.842,0z M135.119,46.323l0.588,4.188c0.325,2.314-1.278,4.437-3.58,4.761
  l-31.31,4.4c-2.31,0.324-4.431-1.294-4.757-3.61L89.308,8.01c-0.326-2.318,1.286-4.46,3.603-4.785l20.848-2.93l1.763,12.548
  c0.327,2.322,2.461,3.913,4.772,3.588l10.421-1.464l0.588,4.181l-18.765,2.638c-3.467,0.487-5.881,3.688-5.394,7.151l2.058,14.638
  c0.486,3.462,3.668,5.878,7.155,5.388l18.765-2.637L135.119,46.323z"
      />
      <path
        fill={THEMES.COLORED_FOREGROUND_LOW({ theme: styledTheme })}
        d="M300.163,100.475l1,2.747l-2.751,1.001c-0.765,0.278-1.599-0.114-1.875-0.873l-1.499-4.12
  c-0.278-0.764,0.116-1.598,0.875-1.874l4.125-1.502l-0.499-1.373l-4.119,1.499c-1.522,0.554-2.313,2.222-1.754,3.759l1.493,4.104
  c0.554,1.522,2.232,2.308,3.76,1.752l4.119-1.499l-2-5.494l-4.125,1.502l0.5,1.373L300.163,100.475z M286.287,100.859l1,2.746
  l2.751-1.001c0.76-0.276,1.152-1.109,0.874-1.874c-0.275-0.758-1.108-1.151-1.873-0.873L286.287,100.859z M285.412,102.733
  l2.499,6.867l1.375-0.501l-1.499-4.12l2.743-0.999c1.522-0.554,2.313-2.223,1.757-3.75c-0.552-1.518-2.229-2.3-3.757-1.743
  l-4.118,1.499L285.412,102.733z M278.657,109.848c0.555,1.522,2.232,2.309,3.761,1.752l1.361-0.495
  c1.521-0.555,2.313-2.222,1.754-3.759l-2.496-6.859l-1.375,0.501l2.504,6.88c0.27,0.741-0.119,1.584-0.879,1.86l-1.378,0.502
  c-0.765,0.278-1.598-0.115-1.873-0.874l-0.5-1.373l-1.375,0.501L278.657,109.848z M298.547,91.73
  c1.502-0.547,3.189,0.227,3.739,1.737l3.508,9.637c0.546,1.499-0.232,3.183-1.748,3.734l-23.393,8.514
  c-1.502,0.547-3.189-0.227-3.739-1.737l-3.507-9.636c-0.546-1.5,0.231-3.184,1.747-3.734L298.547,91.73z M273.788,83.633
  l2.998,8.236c0.276,0.761,1.121,1.152,1.864,0.882l6.888-2.507L273.788,83.633z M294.537,114.97c0.554,1.521-0.23,3.19-1.747,3.742
  l-20.635,7.511c-1.521,0.554-3.195-0.235-3.749-1.757l-11.49-31.569c-0.555-1.522,0.233-3.206,1.76-3.762l13.739-5.001l3,8.244
  c0.556,1.525,2.233,2.294,3.757,1.74l6.868-2.5l1,2.747l-12.367,4.501c-2.284,0.831-3.464,3.349-2.636,5.624l3.5,9.617
  c0.828,2.274,3.337,3.45,5.635,2.613l12.366-4.501L294.537,114.97z"
      />
      <path
        fill={THEMES.COLORED_FOREGROUND_LOW({ theme: styledTheme })}
        d="M145.383,76.897l-1.819,8.564c-0.169,0.791,0.315,1.563,1.059,1.722l6.89,1.465L145.383,76.897z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={THEMES.COLORED_FOREGROUND_LOW({ theme: styledTheme })}
        d="M144.256,88.654c-1.541-0.328-2.518-1.865-2.18-3.46l1.83-8.61
  l-13.887-2.952c-1.543-0.328-3.066,0.695-3.404,2.286l-7.01,32.976c-0.338,1.59,0.625,3.143,2.164,3.47l20.856,4.434
  c1.533,0.325,3.048-0.688,3.385-2.275l0.612-2.872l3.965-18.65l0.609-2.869L144.256,88.654z M141.268,96.266
  c0.219-1.035,1.229-1.698,2.255-1.48c1.025,0.219,1.679,1.234,1.458,2.27c-0.22,1.035-1.229,1.697-2.255,1.479
  C141.699,98.316,141.047,97.301,141.268,96.266z M143.925,110.821l-20.71-4.402l0.94-4.427l8.826-6.577l6.894,9.919l5.701-2.344
  L143.925,110.821z"
      />
      <path
        fill={THEMES.COLORED_FOREGROUND_HIGH({ theme: styledTheme })}
        d="M240.933,65.465l-32.274-2.824c-2.091-0.183-3.934,1.365-4.113,3.424l-1.157,13.225
  c-0.182,2.074,1.385,3.905,3.457,4.087l32.273,2.823c2.091,0.183,3.934-1.365,4.114-3.424l1.157-13.225
  C244.57,67.478,243.005,65.646,240.933,65.465z M219.557,69.293l-5.691-0.498c-1.048-0.092-1.971,0.671-2.062,1.719l-0.495,5.655
  c-0.091,1.04,0.678,1.958,1.732,2.051l3.795,0.332l0.33-3.771l-3.795-0.332l0.165-1.885l5.691,0.498l-0.659,7.54l-5.683-0.497
  c-2.107-0.185-3.656-2.025-3.473-4.114l0.492-5.632c0.185-2.109,2.035-3.633,4.135-3.449l5.683,0.497L219.557,69.293z
  M227.146,69.956l-1.897-0.166l-0.824,9.426l1.897,0.165l-0.165,1.886l-5.691-0.498l0.164-1.885l1.897,0.166l0.825-9.425
  l-1.897-0.166l0.165-1.886l5.691,0.498L227.146,69.956z M238.529,70.952l-7.589-0.664l-0.33,3.771l5.691,0.498l-0.164,1.885
  l-5.692-0.498l-0.494,5.655l-1.897-0.166l1.154-13.195l9.486,0.83L238.529,70.952z"
      />
      <path
        fill={THEMES.COLORED_FOREGROUND_HIGH({ theme: styledTheme })}
        d="M216.174,42.405l-0.989,11.304c-0.091,1.045,0.69,1.966,1.715,2.056l9.504,0.831L216.174,42.405z"
      />
      <path
        fill={THEMES.COLORED_FOREGROUND_HIGH({ theme: styledTheme })}
        d="M223.765,86.755l-0.331,3.776c-0.183,2.086-2.025,3.615-4.118,3.433l-28.468-2.491
  c-2.101-0.184-3.641-2.025-3.458-4.114l3.79-43.326c0.184-2.09,2.037-3.634,4.143-3.449l18.956,1.658l-0.99,11.313
  c-0.183,2.095,1.374,3.915,3.477,4.1l9.475,0.828l-0.329,3.771l-17.062-1.493c-3.151-0.275-5.926,2.03-6.199,5.154l-1.155,13.198
  c-0.272,3.122,2.04,5.875,5.211,6.152l17.062,1.492L223.765,86.755z"
      />
      <path
        fill={THEMES.COLORED_FOREGROUND_HIGH({ theme: styledTheme })}
        d="M55.629,146.282l10.879-3.746c2.854-0.982,4.374-4.078,3.389-6.938l-1.777-5.173l-23.262,8.009
  c-4.322,1.488-8.973-0.791-10.445-5.069l-6.229-18.089c-1.474-4.28,0.814-8.946,5.11-10.426l23.261-8.009l-1.779-5.167
  l-12.917,4.448c-2.866,0.986-5.978-0.505-6.966-3.375l-5.339-15.506L3.711,86.141C0.84,87.129-0.687,90.25,0.3,93.114l18.308,53.168
  H55.629z"
      />
      <polygon
        fill={THEMES.COLORED_FOREGROUND_LOW({ theme: styledTheme })}
        points="202.779,144.439 202.779,144.441 202.781,144.441 "
      />
      <path
        fill={THEMES.COLORED_FOREGROUND_LOW({ theme: styledTheme })}
        d="M174.519,146.282h13.777c-1.929-0.27-3.518-1.764-3.8-3.781l-1.452-10.327
  c-0.344-2.443,1.359-4.701,3.804-5.045l13.238-1.86l-0.415-2.949l-7.352,1.033c-1.63,0.229-3.136-0.894-3.366-2.532l-1.243-8.851
  l-14.707,2.066c-1.634,0.23-2.771,1.74-2.542,3.376L174.519,146.282z"
      />
      <path
        fill={THEMES.COLORED_FOREGROUND_LOW({ theme: styledTheme })}
        d="M192.089,121.879l7.373-1.036l-10.283-9.082l1.243,8.844C190.537,121.423,191.295,121.992,192.089,121.879z"
      />
      <path
        fill={THEMES.COLORED_FOREGROUND_LOW({ theme: styledTheme })}
        d="M192.083,132.408l-2.945,0.415l0.414,2.949l2.946-0.414c0.812-0.114,1.378-0.861,1.263-1.681
  C193.646,132.862,192.901,132.293,192.083,132.408z"
      />
      <polygon
        fill={THEMES.COLORED_FOREGROUND_LOW({ theme: styledTheme })}
        points="203.037,146.282 202.779,144.441 189.675,146.282 "
      />
      <path
        fill={THEMES.COLORED_FOREGROUND_LOW({ theme: styledTheme })}
        d="M216.88,137.958l-1.454-10.346c-0.228-1.623-1.742-2.751-3.35-2.525l-25.04,3.519
  c-1.622,0.229-2.75,1.74-2.523,3.352l1.454,10.346c0.229,1.622,1.741,2.751,3.35,2.524l25.04-3.519
  C215.979,141.08,217.106,139.568,216.88,137.958z M192.696,136.834l-2.937,0.412l0.622,4.424l-1.472,0.207l-1.037-7.374l-0.414-2.95
  l4.408-0.62c1.635-0.23,3.137,0.906,3.366,2.535C195.463,135.109,194.325,136.604,192.696,136.834z M203.629,139.81l-5.452-6.754
  l1.037,7.372l-1.472,0.208l-1.452-10.323l1.472-0.207l5.452,6.754l-1.037-7.375l1.472-0.206l1.452,10.323L203.629,139.81z
  M209.524,138.98c-1.635,0.229-3.138-0.91-3.367-2.545l-0.62-4.406c-0.231-1.649,0.907-3.144,2.536-3.373l4.408-0.621l0.208,1.476
  l-4.416,0.621c-0.812,0.114-1.381,0.862-1.264,1.682l0.621,4.424c0.114,0.813,0.861,1.383,1.68,1.269l2.944-0.415l-0.414-2.949
  l-2.944,0.414l-0.208-1.475l4.416-0.621l0.829,5.898L209.524,138.98z"
      />
      <path
        fill={THEMES.COLORED_FOREGROUND_HIGH({ theme: styledTheme })}
        d="M324.953,144.491l11.309,1.791h50.905l0.979-6.182l-32.245-5.107c-5.992-0.949-10.036-6.425-9.114-12.242
  l3.896-24.595c0.922-5.82,6.495-9.77,12.451-8.826l32.248,5.107l1.112-7.024l-17.907-2.837c-3.974-0.629-6.7-4.255-6.082-8.157
  l3.339-21.082l-35.827-5.675c-3.979-0.631-7.703,2.014-8.32,5.909l-12.787,80.735C318.293,140.199,320.983,143.862,324.953,144.491z
  "
      />
      <polygon
        fill={THEMES.COLORED_FOREGROUND_HIGH({ theme: styledTheme })}
        points="388.146,140.097 388.146,140.101 388.149,140.102 "
      />
      <path
        fill={THEMES.COLORED_FOREGROUND_HIGH({ theme: styledTheme })}
        d="M379.086,81.051l17.962,2.846L379.427,55.9l-3.336,21.064C375.782,78.911,377.149,80.744,379.086,81.051z"
      />
      <path
        fill={THEMES.COLORED_FOREGROUND_HIGH({ theme: styledTheme })}
        d="M371.642,105.077l-7.176-1.137l-1.112,7.025l7.176,1.136c1.979,0.313,3.829-0.992,4.139-2.945
  C374.975,107.218,373.636,105.393,371.642,105.077z"
      />
      <path
        fill={THEMES.COLORED_FOREGROUND_HIGH({ theme: styledTheme })}
        d="M423.531,102.493l-61-9.661c-3.951-0.626-7.654,2.028-8.262,5.864l-3.903,24.644
  c-0.612,3.864,2.13,7.51,6.046,8.13l61,9.662c3.951,0.625,7.654-2.028,8.262-5.864l3.903-24.645
  C430.189,106.76,427.447,103.113,423.531,102.493z M378.254,109.725c-0.619,3.906-4.333,6.516-8.303,5.887l-7.154-1.134
  l-1.669,10.538l-3.586-0.568l2.782-17.562l1.112-7.025l10.74,1.701C376.16,102.191,378.868,105.844,378.254,109.725z
  M399.766,113.153l-1.662,10.496c-0.623,3.931-4.336,6.536-8.306,5.907l-10.74-1.701l3.895-24.588l10.739,1.701
  C397.676,105.6,400.382,109.262,399.766,113.153z M421.841,113.027l-14.344-2.271l-1.112,7.025l10.758,1.703l-0.557,3.513
  l-10.758-1.704l-1.669,10.538l-3.586-0.567l3.895-24.589l17.93,2.84L421.841,113.027z"
      />
      <path
        fill={THEMES.COLORED_FOREGROUND_HIGH({ theme: styledTheme })}
        d="M393.157,108.484l-7.176-1.137l-2.781,17.563l7.175,1.136c1.979,0.313,3.83-0.992,4.139-2.944l1.67-10.538
  C396.49,110.626,395.15,108.801,393.157,108.484z"
      />
    </svg>
  );
};
