import { ApolloClient, useQuery } from "@apollo/client";
import SCHEDULE_DROPDOWN_QUERY from "../../../graphql/query/BroadcastScheduleDropdownTypes.graphql";

interface LinkHostProps {
  client: ApolloClient<object>;
  id: string;
}

const useGetRecurDropdown = ({ client, name }: LinkHostProps) => {
  const { data, loading } = useQuery(SCHEDULE_DROPDOWN_QUERY, {
    client,
    variables: {
      name,
    },
  });

  return { data, loading };
};

export default useGetRecurDropdown;
