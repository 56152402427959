import { useState, useEffect } from "react";
import debounce from "lodash/debounce";
import { useQuery } from "@apollo/client";
import useForm from "../../../utils/hooks/useForm.ts";
import useUploadAttachment from "../attachment/useUploadAttachment";
import useStartPaymentThreadMutation from "../../../api/mutations/useStartPaymentThreadMutation";
import REQUIRED_PAYMENT_REQUEST_FIELDS_QUERY from "../../../graphql/query/RequiredPaymentRequestFieldsQuery.graphql";

const defaultFormValues = {
  fromContact: {
    value: "",
    text: "",
  },
  toContact: {
    value: "",
    text: "",
    accountName: "",
    phoneNumber: "",
    emailAddress: "",
    conversationsOptOut: false,
    emailsOptOut: false,
  },
  messageType: {
    value: "",
    text: "",
  },
  amount: "",
  invoiceNumber: "",
  template: {
    value: "",
    text: "",
    message: "",
  },
  subject: "",
  message: "",
  url: "",
};

const usePaymentWizard = ({
  client,
  initialFormValues = {},
  onMutationStart = () => {},
  onMutationSuccess = () => {},
  onMutationError = () => {},
  i18n,
  senderGroupId,
}) => {
  const { data } = useQuery(REQUIRED_PAYMENT_REQUEST_FIELDS_QUERY, {
    client,
    variables: { senderGroupId },
    skip: !senderGroupId,
  });

  const requiredPaymentRequestFields = data?.requiredPaymentRequestFields;

  const [handleStartPaymentThread] = useStartPaymentThreadMutation({ client });

  const validationOverrideAction = (field, __, currentValidity) => {
    if (!field.otherProps.required) {
      field.setError("");
      return true;
    }

    return currentValidity;
  };

  const urlValidation = {
    type: "urlFormat",
    errorMessage: `${i18n.t(
      "slideouts-PaymentForm-invalidurl"
    )} https://prokeepelectrical.com`,
    optional: true,
  };

  const maxIntSize = {
    type: "maxIntSize",
    errorMessage: i18n.t("slideouts-PaymentForm-amountTooLarge"),
  };

  const requiredValidation = {
    type: "required",
    errorMessage: "Field is required.",
  };

  const initialValues = {
    fromContact: {
      value: initialFormValues?.fromContact?.value
        ? initialFormValues?.fromContact?.value
        : defaultFormValues?.fromContact?.value,
      otherProps: {
        text: initialFormValues?.fromContact?.text
          ? initialFormValues?.fromContact?.text
          : defaultFormValues?.fromContact?.text,
      },
    },
    toContact: {
      value: initialFormValues?.toContact?.value
        ? initialFormValues?.toContact?.value
        : defaultFormValues?.toContact?.value,
      otherProps: {
        text: initialFormValues?.toContact?.text
          ? initialFormValues?.toContact?.text
          : defaultFormValues?.toContact?.text,
        accountName: initialFormValues?.toContact?.accountName
          ? initialFormValues?.toContact?.accountName
          : defaultFormValues?.toContact?.accountName,
        phoneNumber: initialFormValues?.toContact?.phoneNumber
          ? initialFormValues?.toContact?.phoneNumber
          : defaultFormValues?.toContact?.phoneNumber,
        emailAddress: initialFormValues?.toContact?.emailAddress
          ? initialFormValues?.toContact?.emailAddress
          : defaultFormValues?.toContact?.emailAddress,
        conversationsOptOut: initialFormValues?.toContact?.conversationsOptOut
          ? initialFormValues?.toContact?.conversationsOptOut
          : defaultFormValues?.toContact?.conversationsOptOut,
        emailsOptOut: initialFormValues?.toContact?.emailsOptOut
          ? initialFormValues?.toContact?.emailsOptOut
          : defaultFormValues?.toContact?.emailsOptOut,
      },
    },
    messageType: {
      value: initialFormValues?.messageType?.value
        ? initialFormValues?.messageType?.value
        : defaultFormValues?.messageType?.value,
      otherProps: {
        text: initialFormValues?.messageType?.text
          ? initialFormValues?.messageType?.text
          : defaultFormValues?.messageType?.text,
      },
    },
    amount: {
      value: initialFormValues?.amount
        ? initialFormValues?.amount
        : defaultFormValues?.amount,
      validations: [requiredValidation, maxIntSize],
    },

    invoiceNumber: {
      value: initialFormValues?.invoiceNumber
        ? initialFormValues?.invoiceNumber
        : defaultFormValues?.invoiceNumber,
      validations: [requiredValidation],
    },
    template: {
      value: initialFormValues?.template?.value
        ? initialFormValues?.template?.value
        : defaultFormValues?.template?.value,
      otherProps: {
        text: initialFormValues?.template?.text
          ? initialFormValues?.template?.text
          : defaultFormValues?.template?.text,
        message: initialFormValues?.template?.message
          ? initialFormValues?.template?.message
          : defaultFormValues?.template?.message,
      },
    },
    subject: {
      value: initialFormValues?.subject
        ? initialFormValues?.subject
        : defaultFormValues?.subject,
    },
    message: {
      value: initialFormValues?.message
        ? initialFormValues?.message
        : defaultFormValues?.message,
      validations: [requiredValidation],
    },
    url: {
      value: initialFormValues?.url
        ? initialFormValues?.url
        : defaultFormValues?.url,
      validations: [urlValidation, requiredValidation],
      otherProps: { validationOverrideAction, required: true },
    },
  };

  const [tags, setTags] = useState([]); // removed tags and attachments from form as they have special requirements
  const [attachment, setAttachment] = useState(null); // may add to form later

  const [sendAttachmentAsLink, setSendAttachmentAsLink] = useState(false);

  const uploadAttachment = useUploadAttachment({ client });

  const { fields, isVisitedForm, onSubmit, updateField } = useForm(
    initialValues,
    debounce(async (currentFields) => {
      const {
        fromContact,
        toContact,
        messageType,
        amount,
        invoiceNumber,
        template,
        subject,
        message,
        url,
      } = currentFields;

      onMutationStart();

      const amountInCents = parseFloat(amount.value) * 100;

      let attachments = [];
      if (attachment) {
        const uploadedAttachment = await uploadAttachment(attachment).catch(
          (err) => {
            console.error(err);
          }
        );
        attachments = [
          {
            url: uploadedAttachment?.url,
            method: sendAttachmentAsLink ? "LINK" : "EMBED",
          },
        ];
      }

      const clearFields = () => {
        // clear out form first
        updateField({
          name: "messageType",
          value: "",
          otherProps: { text: "" },
        });
        updateField({
          name: "toContact",
          value: "",
          otherProps: {
            text: "",
            accountName: "",
            phoneNumber: "",
            emailAddress: "",
          },
        });
        updateField({
          name: "fromContact",
          value: "",
          otherProps: { text: "" },
        });

        updateField({ name: "amount", value: "", unforceVisit: true });
        updateField({ name: "message", value: "", unforceVisit: true });
        updateField({ name: "invoiceNumber", value: "", unforceVisit: true });
        updateField({
          name: "template",
          value: "",
          otherProps: { message: "", text: "" },
        });
        updateField({ name: "subject", value: "" });
        updateField({
          name: "url",
          value: "",
          otherProps: { required: true, validationOverrideAction },
          unforceVisit: true,
        });
        setTags([]);
        setAttachment(null);
        setSendAttachmentAsLink(false);
      };

      const submitPaymentMessageArgs = {
        createPaymentRequestInput: {
          amount: amountInCents,
          invoice: invoiceNumber.value,
          url: url.value,
        },
        emailMetadata: {
          subject: subject.value,
        },
        externalContactId: toContact.value,
        externalEmailAddress: toContact.otherProps.emailAddress,
        externalPhoneNumber: toContact.otherProps.phoneNumber,
        message: {
          attachments,
          text: message.value,
          template: {
            id: template.value,
          },
        },
        messageType: messageType.value,
        senderGroupId: fromContact.value,
        onSuccess: (data) => {
          onMutationSuccess(data, clearFields);
        },
        onError: onMutationError,
      };

      if (tags.length !== 0) {
        submitPaymentMessageArgs.tags = tags.map((tag) => tag.referenceNumber);
      }

      handleStartPaymentThread(submitPaymentMessageArgs);
    })
  );

  useEffect(() => {
    if (data?.requiredPaymentRequestFields?.staticBaseUrl) {
      updateField({
        name: "url",
        ...fields.url,
        value: data?.requiredPaymentRequestFields?.staticBaseUrl,
      });
    }
  }, [data?.requiredPaymentRequestFields?.staticBaseUrl]);

  return {
    fields,
    isVisitedForm,
    onSubmit,
    updateField,
    attachment,
    setAttachment,
    sendAttachmentAsLink,
    setSendAttachmentAsLink,
    tags,
    setTags,
    requiredPaymentRequestFields,
  };
};

export default usePaymentWizard;
