import { useMutation } from "@apollo/client";
import PASSWORD_RESET_MUTATION from "../../../graphql/mutation/InitiatePasswordResetMutation.graphql";
import useAction from "../../../utils/hooks/useAction";
import useForm from "../../../utils/hooks/useForm.ts";

/*

DOCUMENTATION
  this hook was created to be used with ForgotPassword. handles input values, validation, mutation, and any errors that may arise

  args:
    client: (obj) required. Apollo Client instance

  returns:
    username: (str) state, username value placed inside of text input
    setUsername: (func) will update username
    usernameRef: (react ref) if placed inside input, will re-focus on input if there is a validation error
    validationError: (string) state, will say if username is not valid email
    error: (string) states general error
    complete: (bool) if username successfully found, complete will be true
    clearError: (func) handy way to clear error
    handleSubmit: (func) submits InitiatePasswordResetMutation, will check for valid username first

*/

const useForgotPassword = ({ client }) => {
  const [initiatePasswordReset] = useMutation(PASSWORD_RESET_MUTATION, {
    client,
  });
  const { error, setError, success, setSuccess } = useAction();

  const inputFieldsConfig = {
    username: {
      value: "",
      validations: [
        { type: "required", errorMessage: "Email address is required." },
        { type: "emailFormat", errorMessage: "Invalid email address." },
      ],
    },
  };

  const handleSubmit = async ({ username }) => {
    const response = await initiatePasswordReset({
      variables: { input: { username } },
    }).catch(() => {
      setError("Could not send password reset email.");
      return null;
    });

    if (response.data && response.data?.initiatePasswordReset) {
      setSuccess("Success");
    } else {
      setError("Operation failed. Please try again.");
    }
  };

  const { fields, onSubmit } = useForm(inputFieldsConfig, ({ username }) =>
    handleSubmit({ username: username.value })
  );
  const { username } = fields;

  const clearError = () => setError("");

  return {
    username: username.value,
    setUsername: username.setValue,
    usernameRef: username.ref,
    validationError: username.error,
    error,
    complete: success,
    clearError,
    handleSubmit: onSubmit,
  };
};

export default useForgotPassword;
