import { useState } from "react";
import { useMutation } from "@apollo/client";
import useForm from "../../../../utils/hooks/useForm.ts";
import { normalizePhone } from "../../../../utils/validation";
import updateCustomerContactMutation from "../../../../graphql/mutation/UpdateCustomerContactMutation.graphql";
import updateCustomerAccountMutation from "../../../../graphql/mutation/UpdateCustomerAccountMutation.graphql";

/*
DOCUMENTATION:

useCustomerPhoneEdit is designed to be used with the component CustomerPhoneEdit. It provides phoneNumber (number) state values, a way to set the phone state values,
phone ref, as well as an onSubmit function that will validate to make sure the phone value is valid before executing the phone update mutation. It also has a handy formatted version of the phone number for display purposes.

args:
  customer: (obj) required. this is a CustomerAccount object instance.
  onSave: (func) optional. a callback function the dev would like to run after the success of saving the customers phone
  client: (obj) required. this is an instance of the apolloClient needed for using useMutation outside of web/mobile context

returns:
  [0] number: (obj) state for number value user will change when typing/ save when saves. to get number's value, use number.value, to access a ref attached to the number state, use number.ref.
  [1] formattedNumber: (str) formatted version of number value. place inside JSX
  [2] onChange: handles updating both number and formattedNumber
  [3] onSubmit: (func) fire when user "saves" the new value. This will execute the phone number value validation, and if it passes validations, it will execute the
  mutation necessary to update customer phone number.
*/

function useCustomerPhoneEdit({
  client,
  onSave,
  onError,
  setPhoneNumberError = () => {}, // temp solution for use case bug in payments form, which contains error state
  customer,
  i18n,
}) {
  const [updateCustomer] = useMutation(
    customer.__typename === "CustomerAccount"
      ? updateCustomerAccountMutation
      : updateCustomerContactMutation,
    { client }
  );

  const inputFieldsConfig = {
    number: {
      value: customer.phoneNumber || "",
      validations: [
        {
          type: "phoneFormat",
          errorMessage: i18n.t("slideouts-validation-invalidPhone"),
        },
      ],
      optional: true,
    },
  };

  const sendMutation = async () => {
    const res = await updateCustomer({
      variables: {
        input: {
          id: customer.id,
          phoneNumber: number.value,
        },
      },
    }).catch((err) => {
      onError?.();
      console.warn(err);
    });

    // doing it this way because unit tests are being uncooperative
    const data = res?.data;

    if (data?.updateCustomerContact?.errors?.length) {
      // in future iterations we should rework this error state
      // payments and the slideouts require different functionality
      // so this is something to return to
      const error = data?.updateCustomerContact?.errors[0]?.reason;
      if (error === "phone number in use") {
        setPhoneNumberError(
          i18n.t("settings-ProfileFormContainer-phoneNumberError")
        );
        onError?.(
          i18n.t("settings-ProfileFormContainer-phoneNumberError", {
            defaultValue: "Phone number is already in use",
          })
        );
      } else {
        onError?.();
      }
    } else {
      onSave(number?.value);
    }
  };

  const { fields, onSubmit } = useForm(inputFieldsConfig, sendMutation);
  const { number } = fields;

  const [formattedNumber, updateFormattedNumber] = useState(
    normalizePhone(number.value)
  );

  const onChange = (e) => {
    setPhoneNumberError("");
    const { value, selectionStart } = e.target;

    const charCountAfterSelection = value.slice(selectionStart).length;

    const strippedValue = value.replace(/[^0-9]/g, "");
    const formattedNumber = normalizePhone(strippedValue);

    number.setValue(strippedValue); // updates form state
    updateFormattedNumber(formattedNumber); // updates formatted state

    // from here on is logic to place the cursor in the correct spot

    // indicates that 2 chars were lost instead of one (backspacing until hyphen gets removed with char due to auto-formatting)
    const charInsertionOffset = value.length - formattedNumber.length;

    // compensates for character insertion for autoformatting. Will assume if cursor is at the end that user would like to stay at the end.
    let correctCursorPos =
      formattedNumber.length -
      charCountAfterSelection +
      (charCountAfterSelection === 0 ? 0 : charInsertionOffset);

    // if typing, jump hyphen, if backspacing, don't.
    const jumpHyphen =
      formattedNumber[correctCursorPos - 1] === "-" &&
      value[selectionStart - 1] !== "-";
    if (jumpHyphen) correctCursorPos += 1;

    // set timeout b/c setting state is async.
    setTimeout(() => {
      if (number?.ref?.current)
        number.ref.current.setSelectionRange(
          correctCursorPos,
          correctCursorPos
        );
    }, 0);
  };

  return [number, formattedNumber, onChange, onSubmit];
}

export default useCustomerPhoneEdit;
