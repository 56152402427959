import React, { useRef } from 'react';
import i18n from 'i18n-js';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { formatChannelLabel } from 'client-lib/src/lib/utils/helpers';
import {
  TextInput,
  AsyncSelect,
  InputLabel,
  InputError,
} from '../../../../../elements';
import THEMES from '../../../../../styles/themes/app';

const NameChannelContainer = styled.div`
  background-color: ${THEMES.BACKGROUND_PRIMARY};
  box-shadow: ${THEMES.BOX_SHADOW};
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 30px;
`;

const ChannelSelectContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

const GroupMessageOverviewName = ({
  error,
  messageName,
  setMessageName,
  messageError,
  outboundChannelId,
  pageError,
  setOutboundChannelId,
  wizardGlobalProps,
  disabled,
}) => {
  const nameInput = useRef();

  const channelSelect = () => {
    return (
      <ChannelSelectContainer>
        <InputLabel
          error={error}
          dataTestId="group-message-channel-select-label"
        >
          {i18n.t('broadcasts-BroadcastList-sender')}
        </InputLabel>
        <AsyncSelect
          defaultOptions={wizardGlobalProps?.channelsData?.channels.map(
            (channel) => ({
              value: channel.id,
              label: formatChannelLabel(channel),
            })
          )}
          loadOptions={() =>
            wizardGlobalProps?.channelsData?.channels.map((channel) => ({
              value: channel.id,
              label: formatChannelLabel(channel),
            }))
          }
          value={wizardGlobalProps?.channelsData?.channels
            .filter((c) => c.id === outboundChannelId)
            .map((c) => ({
              value: c.id,
              label: formatChannelLabel(c),
            }))
            .pop()}
          onChange={(e) => setOutboundChannelId(e.value)}
          error={pageError}
          dataTestId="group-message-channel-select"
          disabled={disabled}
          hideBottomSpace
        />
        <InputError
          error={error}
          helperText={i18n.t('slideouts-GroupMessageOverview-sendingNumber')}
          dataTestId="group-message-channel-select-helper"
        />
      </ChannelSelectContainer>
    );
  };

  return (
    <NameChannelContainer>
      {channelSelect()}
      <TextInput
        label={i18n.t('slideouts-GroupMessageName-messageName')}
        type="text"
        value={messageName}
        disabled={!outboundChannelId || disabled}
        onChange={(e) => setMessageName(e.target.value)}
        placeholder={i18n.t('slideouts-GroupMessageOverview-specialPromotion')}
        error={messageError}
        ref={nameInput}
        dataTestId="group-message-name-input"
        helperText={i18n.t('slideouts-GroupMessageOverview-descriptiveName')}
      />
    </NameChannelContainer>
  );
};

GroupMessageOverviewName.propTypes = {
  error: PropTypes.string,
  messageName: PropTypes.string,
  setMessageName: PropTypes.func.isRequired,
  messageError: PropTypes.string,
  outboundChannelId: PropTypes.string,
  pageError: PropTypes.string,
  setOutboundChannelId: PropTypes.func.isRequired,
  wizardGlobalProps: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

GroupMessageOverviewName.defaultProps = {
  error: '',
  messageName: '',
  messageError: '',
  outboundChannelId: '',
  pageError: '',
  disabled: false,
};

export default GroupMessageOverviewName;
