import styled from 'styled-components';
import { Heading5 } from '../Text/Text';
import THEMES from '../../styles/themes/app';

const InputLabel = styled(Heading5)<{ isRequired: boolean }>`
  margin: 8px 0;

  ${(props) =>
    props.isRequired &&
    `
    position: relative;

    &::after {
      content: '';
      position: absolute;
      display: inline-block;
      width: 4px;
      height: 4px;
      top: 50%;
      right: -10px;
      border-radius: 50%;
      transform: translateY(-50%);
      background-color: ${THEMES.THEME_RED(props)};
    }
  `}
`;

export default InputLabel;
