import React, { Suspense, lazy } from 'react';
import { Redirect } from 'react-router-dom';
import { Switch, Route } from 'react-router';
import PropTypes from 'prop-types';
import PublicRoute from '../containers/Login/PublicRoute';
import PrivateRoute from '../containers/Login/PrivateRoute';
import Login from '../containers/Login';
import NotFound from '../components/NotFound';
import NoAuth from '../components/NoAuth';
import SsoAuthFail from '../components/SsoAuthFail';
import ErrorMessagePage from '../ErrorMessagePage';
import LazyFallback from '../components/LazyFallback/LazyFallback';
import AzureSuccess from '../components/AzureSuccess';

const ReportsContainer = lazy(() => import('../containers/ReportsContainer'));
const Search = lazy(() => import('../components/Search'));
const Settings = lazy(() => import('../components/Settings'));
const Announcements = lazy(() => import('../components/Announcements'));
const Tasks = lazy(() => import('../components/Tasks/index.tsx'));
const Threads = lazy(() => import('../components/Threads'));
const CustomersContainer = lazy(
  () => import('../containers/Customers/CustomersContainer')
);
const DashboardAndFeedback = lazy(
  () => import('../components/DashboardAndFeedback/index.tsx')
);

const Routes = ({
  allClosedThreadsQueryHandling,
  allOpenThreadsQueryHandling,
  myClosedThreadsQueryHandling,
  myOpenThreadsQueryHandling,
  myInboxThreadsQueryHandling,
  inboxSpamThreadsQueryHandling,
  announcementsPagePermission,
  reportsPagePermission,
  dashboardPagePermission,
  feedbackPagePermission,
  includeInternal,
  taskPagePermission,
}) => {
  return (
    <Switch>
      <Redirect exact from="/" to="/login" />

      <Redirect exact from="/inbox" to="/threads/inbox" />

      <Redirect exact from="/threads" to="/threads/inbox" />

      {/* fix for outdated urls that users attempt to go to */}
      <Redirect from="/threads/mine" to="/threads/open" />
      <Redirect from="/threads/recent" to="/threads/closed" />

      <PublicRoute path="/login">{(props) => <Login {...props} />}</PublicRoute>

      <PublicRoute path="/forgot">
        {(props) => <Login {...props} />}
      </PublicRoute>

      <PublicRoute path="/diagnostics">
        {(props) => <Login {...props} />}
      </PublicRoute>

      <PublicRoute path="/reset/:token">
        {(props) => <Login {...props} />}
      </PublicRoute>

      <PublicRoute path="/join/:token" allowAuthenticatedUsers>
        {(props) => <Login {...props} />}
      </PublicRoute>

      <PublicRoute path="/noauth">
        {(props) => <NoAuth {...props} />}
      </PublicRoute>

      <PublicRoute path="/ssoauthfail">
        {(props) => <SsoAuthFail {...props} />}
      </PublicRoute>

      <PublicRoute path="/notfound">
        {(props) => <NotFound {...props} />}
      </PublicRoute>

      <PublicRoute allowAuthenticatedUsers exact path="/azuresuccess">
        {(props) => <AzureSuccess {...props} />}
      </PublicRoute>

      <PrivateRoute
        exact
        path="/contacts/:filter/:activeCustomerId?/:entityType?"
      >
        {(props) => (
          <Suspense fallback={<LazyFallback />}>
            <CustomersContainer {...props} />
          </Suspense>
        )}
      </PrivateRoute>

      <PrivateRoute
        exact
        path="/contacts/:filter/:activeCustomerId?/:entityType?/threads/:activeThreadId"
      >
        {(props) => (
          <Suspense fallback={<LazyFallback />}>
            <CustomersContainer {...props} />
          </Suspense>
        )}
      </PrivateRoute>

      <PrivateRoute exact path="/threads/:filter/:activeThreadId?">
        {(props) => (
          <Suspense fallback={<LazyFallback />}>
            <Threads
              allClosedThreadsQueryHandling={allClosedThreadsQueryHandling}
              allOpenThreadsQueryHandling={allOpenThreadsQueryHandling}
              myClosedThreadsQueryHandling={myClosedThreadsQueryHandling}
              myInboxThreadsQueryHandling={myInboxThreadsQueryHandling}
              myOpenThreadsQueryHandling={myOpenThreadsQueryHandling}
              inboxSpamThreadsQueryHandling={inboxSpamThreadsQueryHandling}
              includeInternal={includeInternal}
              {...props}
            />
          </Suspense>
        )}
      </PrivateRoute>

      {dashboardPagePermission ? (
        <PrivateRoute path="/dashboard">
          {(props) => (
            <Suspense fallback={<LazyFallback />}>
              <DashboardAndFeedback
                {...props}
                dashboardPagePermission={dashboardPagePermission}
                feedbackPagePermission={feedbackPagePermission}
              />
            </Suspense>
          )}
        </PrivateRoute>
      ) : null}

      {taskPagePermission ? (
        <PrivateRoute path="/tasks/:taskId">
          {(props) => (
            <Suspense fallback={<LazyFallback />}>
              <Tasks {...props} />
            </Suspense>
          )}
        </PrivateRoute>
      ) : null}

      {taskPagePermission ? (
        <PrivateRoute path="/tasks">
          {(props) => (
            <Suspense fallback={<LazyFallback />}>
              <Tasks {...props} />
            </Suspense>
          )}
        </PrivateRoute>
      ) : null}

      {feedbackPagePermission ? (
        <PrivateRoute exact path="/feedback">
          {(props) => (
            <Suspense fallback={<LazyFallback />}>
              <DashboardAndFeedback
                {...props}
                dashboardPagePermission={dashboardPagePermission}
                feedbackPagePermission={feedbackPagePermission}
              />
            </Suspense>
          )}
        </PrivateRoute>
      ) : null}

      {feedbackPagePermission ? (
        <PrivateRoute exact path="/feedback/:feedbackId">
          {(props) => (
            <Suspense fallback={<LazyFallback />}>
              <DashboardAndFeedback
                {...props}
                dashboardPagePermission={dashboardPagePermission}
                feedbackPagePermission={feedbackPagePermission}
              />
            </Suspense>
          )}
        </PrivateRoute>
      ) : null}

      {announcementsPagePermission ? (
        <PrivateRoute path="/broadcasts">
          {(props) => (
            <Suspense fallback={<LazyFallback />}>
              <Announcements {...props} />
            </Suspense>
          )}
        </PrivateRoute>
      ) : null}

      {reportsPagePermission ? (
        <PrivateRoute exact path="/reports">
          {(props) => (
            <Suspense fallback={<LazyFallback />}>
              <ReportsContainer {...props} />
            </Suspense>
          )}
        </PrivateRoute>
      ) : null}

      <PrivateRoute path="/search">
        {(props) => (
          <Suspense fallback={<LazyFallback />}>
            <Search includeInternal={includeInternal} {...props} />
          </Suspense>
        )}
      </PrivateRoute>

      <PrivateRoute path="/settings">
        {(props) => (
          <Suspense fallback={<LazyFallback />}>
            <Settings {...props} />
          </Suspense>
        )}
      </PrivateRoute>

      <Route path="/error" component={ErrorMessagePage} />

      <Route component={NotFound} />
    </Switch>
  );
};

Routes.propTypes = {
  allClosedThreadsQueryHandling: PropTypes.object,
  allOpenThreadsQueryHandling: PropTypes.object,
  myClosedThreadsQueryHandling: PropTypes.object,
  myOpenThreadsQueryHandling: PropTypes.object,
  myInboxThreadsQueryHandling: PropTypes.object,
  inboxSpamThreadsQueryHandling: PropTypes.object,
  announcementsPagePermission: PropTypes.bool,
  reportsPagePermission: PropTypes.bool,
  includeInternal: PropTypes.bool,
  dashboardPagePermission: PropTypes.bool,
  feedbackPagePermission: PropTypes.bool,
  taskPagePermission: PropTypes.bool,
};

Routes.defaultProps = {
  allClosedThreadsQueryHandling: {},
  allOpenThreadsQueryHandling: {},
  myClosedThreadsQueryHandling: {},
  myInboxThreadsQueryHandling: {},
  myOpenThreadsQueryHandling: {},
  inboxSpamThreadsQueryHandling: {},
  announcementsPagePermission: false,
  reportsPagePermission: false,
  includeInternal: false,
  dashboardPagePermission: false,
  feedbackPagePermission: false,
  taskPagePermission: false,
};

export default Routes;
