import { useMutation } from "@apollo/client";
import { useState } from "react";
import {
  CREATE_TEMPLATE_MUTATION,
  UPDATE_TEMPLATE_MUTATION,
} from "../../../..";
import { arrayToObject } from "../../../../utils/helpers";
import useForm from "../../../../utils/hooks/useForm.ts";
import useUploadAttachment from "../../attachment/useUploadAttachment";

/*

DOCUMENTATION
  this hook is created to be used with AddOrEditTemplate

  args:
    client: (obj) required. Apollo Client instance.
    onStart: (func) optional. will be called before mutation fires.
    onSuccess: (func) optional. will be called on mutation success.
    onError: (func) optional. will be called on mutation error.
    isEditForm: (bool) optional. is true if template exists.
    templateData: (object) optional. the template data object if editing.
    allGroups: (array) optionaal. all groups for rendering existing groups when editing.
    i18n: (func) required: library for internationalization.

  return:
    fields: (obj) fields object returned by the useForm hook.
    onSubmit: (func) callback function that will fire the join team mutation.
    isVisitedForm: (bool) boolean state returned by the useForm hook.
    updateField: (func) callback function returned by the useForm hook.
    sendAttachmentAsLink: (bool) sets the attachment send method
    setSendAttachmentAsLink: (func) sets state for sendAttachmentLink
*/

const useAddOrEditTemplate = ({
  client,
  onStart,
  onSuccess,
  onError,
  isEditForm,
  templateData,
  isSettingsTemplate,
  allGroups,
  i18n,
}) => {
  let initialTemplateValues = {};
  const allGroupIds = arrayToObject(allGroups, "id");

  if (isEditForm) {
    initialTemplateValues = {
      id: templateData?.id,
      name: templateData?.name,
      message: templateData?.message,
      channelType: templateData?.channelTypes,
      attachment: templateData?.attachments[0],
      groups: templateData?.groupIds,
      regions: templateData?.regions,
      link: templateData?.link,
    };
  } else {
    initialTemplateValues = {
      message: templateData?.message,
      channelType: templateData?.channelTypes,
      attachment: templateData?.attachments,
      link: templateData?.link,
    };
  }

  const [sendAttachmentAsLink, setSendAttachmentAsLink] = useState(false);

  const [createTemplateMutation] = useMutation(CREATE_TEMPLATE_MUTATION, {
    client,
  });
  const [updateTemplateMutation] = useMutation(UPDATE_TEMPLATE_MUTATION, {
    client,
  });
  const uploadAttachment = useUploadAttachment({ client });

  const requiredValidation = {
    type: "required",
    errorMessage: i18n.t("settings-AddOrEditGroup-required", {
      defaultValue: "Required.",
    }),
  };
  const requiredGroupValidation = {
    type: "required",
    errorMessage: i18n.t("settings-AddOrEditGroup-selectGroup", {
      defaultValue: "Select at least 1 group.",
    }),
  };

  const formatToSelectGroupOptions = () => {
    const formatToSelectRegionOptions = () =>
      templateData?.regions?.map((region) => ({
        label: region?.name,
        value: region?.id,
        typename: region?.__typename,
        groups: region?.groups,
      })) || [];

    const formatToSelectOptions = (groupIds) =>
      groupIds?.map((groupId) => ({
        label: allGroupIds[groupId].name,
        value: groupId,
      })) || [];

    const res = [
      ...formatToSelectOptions(initialTemplateValues?.groups),
      ...formatToSelectRegionOptions(initialTemplateValues?.regions),
    ];

    return res;
  };

  const initialValues = {
    name: {
      value: initialTemplateValues?.name || "",
      validations: [requiredValidation],
    },
    message: {
      value: initialTemplateValues?.message || "",
      validations: [requiredValidation],
    },
    channelType: {
      value: initialTemplateValues?.channelType || ["SMS"],
      validations: [requiredValidation],
    },
    attachment: {
      value: initialTemplateValues?.attachment || null,
    },
    groups: {
      value: formatToSelectGroupOptions(),
      validations: [requiredGroupValidation],
    },
    link: {
      value: initialTemplateValues?.link || "",
    },
  };

  const { fields, isVisitedForm, onSubmit, updateField } = useForm(
    initialValues,
    async (formValues) => {
      onStart();
      const regionIds = [];
      formValues.groups?.value.forEach((group) => {
        if (group.typename === "Region") {
          regionIds.push(group.value);
        }
      });
      let groupIds = [];
      if (
        formValues?.groups.value.findIndex(({ value }) => value === "all") !==
        -1
      ) {
        groupIds = ["all"];
      } else {
        formValues?.groups?.value?.forEach((group) => {
          if (group?.typename !== "Region") {
            groupIds.push(group?.value);
          }
        });
      }

      const input = {
        name: formValues.name.value,
        message: formValues.message.value,
        channelTypes: formValues.channelType.value,
        link: formValues.link.value,
        groupIds,
      };

      if (
        groupIds.some((groupId) => groupId === "all") ||
        groupIds.filter((i) => i !== "all").length === allGroups.length
      ) {
        input.groupIds = ["all"];
        input.regionIds = regionIds;
      } else {
        input.groupIds = groupIds;
        input.regionIds = regionIds;
      }

      if (
        formValues?.attachment?.value &&
        templateData?.attachments?.[0]?.id !== formValues?.attachment?.value?.id
      ) {
        const uploadedAttachment = await uploadAttachment(
          formValues?.attachment?.value
        ).catch((err) => {
          console.error(err);
          onError(err);
        });
        input.attachments = [
          {
            url: uploadedAttachment?.url,
            method: sendAttachmentAsLink ? "LINK" : "EMBED",
          },
        ];
      } else if (
        templateData?.attachments?.[0]?.id !== formValues?.attachment?.value?.id
      ) {
        input.attachments = [];
      }

      let res = {};
      let errors = false;

      if (isEditForm) {
        res = await updateTemplateMutation({
          variables: { input: { ...input, id: templateData?.id } },
        });
        const { updateTemplate } = res.data;
        if (
          updateTemplate?.errors !== null &&
          updateTemplate?.errors?.length > 0
        ) {
          errors = true;
        }
      } else {
        res = await createTemplateMutation({ variables: { input } });
        const { createTemplate } = res.data;
        if (
          createTemplate?.errors !== null &&
          createTemplate?.errors?.length > 0
        ) {
          if (
            createTemplate?.errors[0].reason === "Template name already exists"
          ) {
            return onError(
              i18n.t("settings-AddOrEditTemplate-nameExists", {
                defaultValue: "Template name already exists",
              })
            );
          }
          errors = true;
        }
      }

      if (errors) {
        return onError(i18n.t("slideouts-GroupMessageName-genericError"));
      }
      return isEditForm
        ? onSuccess(
            i18n.t("settings-AddOrEditTemplate-successTemplateSettings")
          )
        : isSettingsTemplate
          ? onSuccess(i18n.t("settings-AddOrEditTemplate-successNewTemplate"))
          : onSuccess(i18n.t("broadcasts-BTMFlyout-success"));
    }
  );

  return {
    fields,
    onSubmit,
    isVisitedForm,
    updateField,
    sendAttachmentAsLink,
    setSendAttachmentAsLink,
  };
};

export default useAddOrEditTemplate;
