import React from 'react';
import styled, { type ThemeProps } from 'styled-components';
import THEMES from '../../styles/themes/library/textInput';
import FONTSIZE_THEMES from '../../styles/themes/fontSize/fontSize';

const showError = (error: string | boolean) =>
  error && typeof error === 'string';

const InputErrorText = styled.div<{ error: string | boolean }>`
  font-size: ${FONTSIZE_THEMES.INPUTERROR_TEXT};
  font-weight: 500;
  font-family: 'Barlow', sans-serif;
  margin-left: ${(props) => (showError(props.error) ? '4px' : '0')};
  color: ${(props) =>
    showError(props.error) ? THEMES.ERROR : THEMES.HELPER_TEXT};
`;

const ErrorIcon = styled.i`
  font-size: ${FONTSIZE_THEMES.INPUTERROR_TEXT};
  color: ${THEMES.ERROR};
`;

const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
`;

const WhiteSpaceContainerBelow = styled.div<{
  customInputStyle?: (props: ThemeProps<unknown>) => string;
}>`
  height: 24px;
  padding-top: 8px;
  ${(props) => props?.customInputStyle?.(props)}
`;

interface InputErrorProps {
  error?: boolean | string;
  helperText?: string;
  dataTestId?: string;
  customErrorContainerStyle?: () => string;
}

const InputError = ({
  error = false,
  helperText = '',
  dataTestId = 'input-error-element',
  customErrorContainerStyle = undefined,
}: InputErrorProps) => (
  <WhiteSpaceContainerBelow
    customInputStyle={customErrorContainerStyle}
    className="element-input-error-text-container"
  >
    {helperText && !showError(error) && (
      <InputErrorText error={error} data-testid={dataTestId}>
        {helperText}
      </InputErrorText>
    )}
    {showError(error) ? (
      <ErrorContainer>
        <ErrorIcon className="ri-error-warning-fill" />
        <InputErrorText error={error} data-testid={dataTestId}>
          {error}
        </InputErrorText>
      </ErrorContainer>
    ) : null}
  </WhiteSpaceContainerBelow>
);

export default InputError;
