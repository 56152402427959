import React, { Component } from 'react';
import PropTypes from 'prop-types';

class DetectClickOutside extends Component {
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  handleClick = (e) => {
    const { onClickOutside, classesNotOutside } = this.props;
    let foundToBeInside = false;

    classesNotOutside.forEach((className) => {
      if (
        e.target?.getAttribute('class')?.includes(className) ||
        e.target?.parentNode?.getAttribute('class')?.includes(className) ||
        e.target?.parentNode?.parentNode
          ?.getAttribute('class')
          ?.includes(className)
      ) {
        foundToBeInside = true;
      }
    });

    if (!this.wrapper.contains(e.target) && !foundToBeInside) onClickOutside(e);
  };

  render() {
    return (
      <div
        ref={(wrapper) => {
          this.wrapper = wrapper;
        }}
        data-testid="detect-click-outside"
      >
        {this.props.children}
      </div>
    );
  }
}

DetectClickOutside.propTypes = {
  children: PropTypes.node.isRequired,
  onClickOutside: PropTypes.func.isRequired,
  classesNotOutside: PropTypes.array,
};

DetectClickOutside.defaultProps = {
  classesNotOutside: [],
};

export default DetectClickOutside;
