export const PROKEEP_SUPPORT_LINK = 'https://www.prokeep.com/support';
export const PROKEEP_KNOWLEDGE_CENTER_LINK =
  'https://knowledge.prokeep.com/knowledge';
export const CARRIERS = {
  BANDWIDTH: 'BANDWIDTH',
};
export const BREAKPOINT_LG = '1400px';

// Time in ms
export const FIVE_MIN = 300000;
export const ONE_HOUR = 3600000;
export const FOUR_HOURS = 14400000;
export const EIGHT_HOURS = 28800000;

export const MAX_LIST_LENGTH = 255;

export const MAX_COMBINED_FILE_SIZE = 30000000;

export const MAX_FILE_SIZE = 5000000;

export enum I18N_ERROR_MESSAGES {
  UNSUPPORTED_FILE = 'slideouts-CreateThread-unsupportedFile',
  FILE_SIZE_LIMIT = 'slideouts-CreateSection-fileSizeLimit',
  LIMIT_10 = 'slideouts-CreateInternal-limit10',
  FILE_FAILURE = 'slideouts-CreateEmail-fileFailure',
}

export type I18N_ERROR_VARIABLES = {
  fileSizeLimit?: number; // in MB
  originalFilename?: string;
};

export class I18nError extends Error {
  i18n_id: string;

  i18n_variables?: I18N_ERROR_VARIABLES;

  constructor(
    i18n_id: I18N_ERROR_MESSAGES,
    i18n_variables?: I18N_ERROR_VARIABLES
  ) {
    super(i18n_id);
    this.name = 'I18nError';
    this.i18n_id = i18n_id;
    this.i18n_variables = i18n_variables;
  }
}

export const MAX_TEXTAREA_VALUE_LENGTH = 500;
