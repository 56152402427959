import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import THEMES from '../../styles/themes/library/textInput';
import APP_THEMES from '../../styles/themes/app';
import InputError from '../inputCommonElements/InputError.tsx';
import InputLabel from '../inputCommonElements/InputLabel.ts';
import FONTSIZE_THEMES from '../../styles/themes/fontSize/fontSize';
import IconButton from '../Button/IconButton';

export const TextInputContainer = styled.div`
  width: 100%;
`;

export const baseInputStyle = css`
  width: ${(props) => (props?.hasClearButton ? 'calc(100% - 34px)' : '100%')};
  border: 1px solid;
  box-sizing: border-box;
  padding: 10.5px 0;
  padding-right: ${(props) =>
    props.hasIcon && props.iconRight ? '30px' : '8px'};
  padding-left: 8px;
  text-indent: ${(props) => (props.hasIcon && !props.iconRight ? '22px' : 0)};
  border-radius: 4px;
  font-size: ${FONTSIZE_THEMES.TEXTINPUT_TEXT};
  font-weight: 500;
  font-family: 'Barlow', sans-serif;
  color: ${(props) =>
    props.disabled ? APP_THEMES.FOREGROUND_LOW(props) : THEMES.TEXT(props)};
  outline: none;

  border-color: ${(props) => (props.error ? THEMES.ERROR : THEMES.BORDER)};

  background-color: ${(props) =>
    props.disabled ? THEMES.DISABLED_BACKGROUND : THEMES.BACKGROUND};
  cursor: ${(props) => (props.disabled ? 'default' : 'auto')};

  :focus {
    outline: ${(props) => (props.error ? THEMES.ERROR : THEMES.FOCUS)} auto 1px;
  }
  ::placeholder {
    color: ${THEMES.PLACEHOLDER};
  }
`;

const TextInputStyled = styled.input`
  ${baseInputStyle}
`;

export const IconContainer = styled.div`
  height: 0;
  position: relative;
  display: flex;
  width: 100%;
  justify-content: ${(props) => (props.iconRight ? 'flex-end' : 'flex-start')};

  & i {
    color: ${THEMES.ICON};
    position: relative;
    top: 12px;
    left: ${(props) => (props.iconRight ? 'auto' : '8px')};
    right: ${(props) => (props.iconRight ? '8px' : 'auto')};
    font-size: ${FONTSIZE_THEMES.TEXTINPUT_ICON_TEXT};
    line-height: normal;
  }

  & svg {
    position: relative;
    top: 10px;
    left: ${(props) => (props.iconRight ? 'auto' : '10px')};
    right: ${(props) => (props.iconRight ? '10px' : 'auto')};
    width: 20px;
    height: 20px;
  }
`;

export const RightSideLabelWrapper = styled.div`
  margin: 8px 0;
`;

export const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TextInput = React.forwardRef(
  (
    {
      label,
      rightSideLabelContent,
      placeholder,
      value,
      onChange,
      error,
      helperText,
      disabled,
      icon,
      iconRight,
      hideBottomSpace,
      customContainerStyle,
      customLabelStyle,
      customInputStyle,
      customIconStyle,
      customLabelWrapperStyle,
      dataTestId,
      hasClearButton,
      isRequired,
      ...otherProps
    },
    ref
  ) => (
    <TextInputContainer
      error={error}
      disbled={disabled}
      customContainerStyle={customContainerStyle}
      css={(props) => props?.customContainerStyle?.(props)}
    >
      <LabelWrapper
        customLabelWrapperStyle={customLabelWrapperStyle}
        css={(props) => props?.customLabelWrapperStyle?.(props)}
      >
        {label && (
          <InputLabel
            error={error}
            disbled={disabled}
            customLabelStyle={customLabelStyle}
            css={(props) => props?.customLabelStyle?.(props)}
            isRequired={isRequired}
          >
            {label}
          </InputLabel>
        )}
        {rightSideLabelContent && (
          <RightSideLabelWrapper>{rightSideLabelContent}</RightSideLabelWrapper>
        )}
      </LabelWrapper>
      {icon && (
        <IconContainer
          error={error}
          disbled={disabled}
          customIconStyle={customIconStyle}
          iconRight={iconRight}
          css={(props) => props?.customIconStyle?.(props)}
        >
          {icon}
        </IconContainer>
      )}
      <TextInputStyled
        autoComplete="off"
        placeholder={placeholder}
        value={value}
        error={error}
        onChange={onChange}
        disabled={disabled}
        hasIcon={!!icon}
        iconRight={iconRight}
        hasClearButton={hasClearButton}
        maxLength={500}
        css={(props) => props?.customInputStyle?.(props)}
        customInputStyle={customInputStyle}
        {...otherProps}
        ref={ref}
        data-testid={dataTestId}
      />
      {hasClearButton && (
        <IconButton
          size="lg"
          onClick={() => onChange({ target: { value: '' } })}
          style={{
            float: 'right',
            marginTop: '4px',
          }}
          dataTestId={`${dataTestId}-clear-button`}
        >
          <i className="ri-close-line" />
        </IconButton>
      )}
      {!hideBottomSpace && (
        <InputError
          error={error}
          helperText={helperText}
          dataTestId={dataTestId && `${dataTestId}-helper`}
        />
      )}
    </TextInputContainer>
  )
);

TextInput.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.element,
  iconRight: PropTypes.bool,
  hideBottomSpace: PropTypes.bool,
  customContainerStyle: PropTypes.func,
  customLabelStyle: PropTypes.func,
  customInputStyle: PropTypes.func,
  customIconStyle: PropTypes.func,
  customLabelWrapperStyle: PropTypes.func,
  dataTestId: PropTypes.string,
  rightSideLabelContent: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.bool,
  ]),
  hasClearButton: PropTypes.bool,
  isRequired: PropTypes.bool,
};

TextInput.defaultProps = {
  label: null,
  placeholder: '',
  value: '',
  onChange: null,
  error: false,
  helperText: '',
  disabled: false,
  icon: null,
  iconRight: false,
  hideBottomSpace: false,
  customContainerStyle: null,
  customLabelStyle: null,
  customInputStyle: null,
  customIconStyle: null,
  customLabelWrapperStyle: null,
  dataTestId: null,
  rightSideLabelContent: '',
  hasClearButton: false,
  isRequired: false,
};

TextInput.displayName = 'TextInput';

export default TextInput;
