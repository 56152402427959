import React from 'react';
import PropTypes from 'prop-types';
import ReactCurrencyInput from 'react-currency-input-field';
import styled from 'styled-components';
import InputError from '../inputCommonElements/InputError.tsx';
import InputLabel from '../inputCommonElements/InputLabel.ts';
import {
  baseInputStyle,
  IconContainer,
  LabelWrapper,
  RightSideLabelWrapper,
  TextInputContainer,
} from '../TextInput/TextInput';
import { Text } from '../Text/Text';
import THEMES from '../../styles/themes/app';
import FONTSIZE_THEMES from '../../styles/themes/fontSize/fontSize';

const StyledCurrencyInput = styled(ReactCurrencyInput)`
  ${baseInputStyle}
  text-indent: 9px;
`;

const customIconStyling = (props) => `
  i {
    font-style: normal;
    top: 11px;
    font-size: ${FONTSIZE_THEMES.TEXTINPUT_TEXT(props)};
    line-height: ${FONTSIZE_THEMES.TEXTINPUT_TEXT(props)};
    color: ${THEMES.FOREGROUND_HIGH(props)} !important;
  }
`;

const CurrencyInput = ({
  onChange,
  value,
  error,
  disabled,
  label,
  placeholder,
  rightSideLabelContent,
  hideBottomSpace,
  helperText,
  dataTestId,
  customContainerStyle,
  customInputStyle,
  customLabelWrapperStyle,
  customLabelStyle,
  allowNegativeValue,
  ...props
}) => {
  return (
    <TextInputContainer
      error={error}
      disbled={disabled}
      customContainerStyle={customContainerStyle}
      css={(props) => props?.customContainerStyle?.(props)}
    >
      <LabelWrapper
        customLabelWrapperStyle={customLabelWrapperStyle}
        css={(props) => props?.customLabelWrapperStyle?.(props)}
      >
        {label && (
          <InputLabel
            error={error}
            disbled={disabled}
            customLabelStyle={customLabelStyle}
            css={(props) => props?.customLabelStyle?.(props)}
          >
            {label}
          </InputLabel>
        )}

        {rightSideLabelContent && (
          <RightSideLabelWrapper>{rightSideLabelContent}</RightSideLabelWrapper>
        )}
      </LabelWrapper>
      <IconContainer error={error} disbled={disabled} css={customIconStyling}>
        <Text>
          <i>$</i>
        </Text>
      </IconContainer>
      <StyledCurrencyInput
        decimalsLimit={2}
        onValueChange={(value) => onChange(value)}
        value={value}
        error={error}
        disabled={disabled}
        placeholder={placeholder || '0.00'}
        css={(props) => props?.customInputStyle?.(props)}
        customInputStyle={customInputStyle}
        allowNegativeValue={allowNegativeValue}
        {...props}
      />
      {!hideBottomSpace && (
        <InputError
          error={error}
          helperText={helperText}
          dataTestId={dataTestId && `${dataTestId}-helper`}
        />
      )}
    </TextInputContainer>
  );
};

CurrencyInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  hideBottomSpace: PropTypes.bool,
  customContainerStyle: PropTypes.func,
  customLabelStyle: PropTypes.func,
  customInputStyle: PropTypes.func,
  customLabelWrapperStyle: PropTypes.func,
  dataTestId: PropTypes.string,
  rightSideLabelContent: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.bool,
  ]),
  allowNegativeValue: PropTypes.bool,
};

CurrencyInput.defaultProps = {
  label: null,
  placeholder: null,
  value: '',
  onChange: null,
  error: false,
  helperText: '',
  disabled: false,
  hideBottomSpace: false,
  customContainerStyle: null,
  customLabelStyle: null,
  customInputStyle: null,
  customLabelWrapperStyle: null,
  dataTestId: null,
  rightSideLabelContent: '',
  allowNegativeValue: true,
};

export default CurrencyInput;
