import React, { useState } from 'react';
import { PK_TYPENAMES, SEARCH_CUSTOMERS_QUERY } from 'client-lib';
import { contactName } from 'client-lib/src/lib/utils/helpers';
import {
  normalizePhone,
  phoneRegex,
} from 'client-lib/src/lib/utils/validation';
import i18n from 'i18n-js';
import styled from 'styled-components';

import { InfiniteSuggest, SelectedEntity } from '../../elements';
import type { CustomerContact } from '../../utils/helpers/types';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

interface ContactInputProps {
  contact: CustomerContact | null;
  translatedLabel: string;
  setContact: (contact: CustomerContact | null) => void;
  autoFocus?: boolean;
  dataTestId?: string;
  error?: string;
  hideBottomSpace?: boolean;
  isDisabled?: (node: CustomerContact) => boolean;
  isRequired?: boolean;
  presenceTracking?: boolean;
}

const ContactInput = ({
  contact,
  translatedLabel,
  setContact,
  autoFocus = false,
  dataTestId = 'contact-input',
  error,
  hideBottomSpace = false,
  isDisabled = () => false,
  isRequired = false,
  presenceTracking = false,
}: ContactInputProps): JSX.Element => {
  const [contactText, setContactText] = useState('');
  const [contactPhoneNumber, setContactPhoneNumber] = useState('');

  const certifyToInputValueOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const trimmedValue = e.target.value.trim();

    if (phoneRegex.test(trimmedValue) === true) {
      const number = normalizePhone(trimmedValue);

      setContactPhoneNumber(number);
      setContactText(number);
    } else if (!contact || contactPhoneNumber === '') {
      setContactText('');
    }
  };

  const inferAvatarChildren = (iacContact: CustomerContact | null) => {
    if (iacContact?.__typename === PK_TYPENAMES.CUSTOMER_CONTACT) {
      return iacContact?.firstName || iacContact?.lastName
        ? contactName(iacContact)
        : undefined;
    }

    return undefined;
  };

  const inferEntityCardMainText = (iecmtContact: CustomerContact | null) => {
    if (iecmtContact?.__typename === PK_TYPENAMES.CUSTOMER_CONTACT) {
      return iecmtContact?.firstName || iecmtContact?.lastName
        ? contactName(iecmtContact)
        : contactPhoneNumber;
    }
    if (!iecmtContact && contactPhoneNumber) {
      return contactPhoneNumber;
    }

    return '';
  };

  const inferEntityCardSubText = (iecstContact: CustomerContact | null) => {
    if (iecstContact?.__typename === PK_TYPENAMES.CUSTOMER_CONTACT) {
      return iecstContact?.account?.name || '';
    }

    return '';
  };

  const onSuggestionSelected = (
    _e: React.MouseEvent<HTMLDivElement>,
    { suggestion }: { suggestion: CustomerContact }
  ) => {
    setContact(suggestion);
    setContactPhoneNumber(suggestion.phoneNumber || '');
  };

  const handleInputPropsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setContactText(e.target.value);
  };

  const clearToFieldBubble = () => {
    setContact(null);
    setContactText('');
    setContactPhoneNumber('');
  };

  return (
    <Container>
      {!contact?.id ? (
        <InfiniteSuggest
          inputProps={{
            label: translatedLabel,
            error,
            onBlur: certifyToInputValueOnBlur,
            value: contactText,
            onChange: handleInputPropsChange,
            placeholder: i18n.t('slideouts-CreateThread-enterNameOrNumber'),
            autoFocus,
            hideBottomSpace,
            isRequired,
          }}
          query={SEARCH_CUSTOMERS_QUERY}
          queryKey="searchCustomers"
          queryVariables={{
            query: contactText,
            after: null,
            typeFilter: 'CUSTOMER_CONTACT',
          }}
          onSelect={onSuggestionSelected}
          includePresence={presenceTracking}
          includeGroupIndicator
          dataTestId={`${dataTestId}-select-list`}
          isDisabled={isDisabled}
        />
      ) : (
        <div style={{ position: 'relative' }}>
          <SelectedEntity
            label={i18n.t('slideouts-CreateThread-toLabel')}
            avatarChildren={inferAvatarChildren(contact)}
            maintext={inferEntityCardMainText(contact)}
            subtext={inferEntityCardSubText(contact)}
            onDelete={clearToFieldBubble}
            customDeleteButtonProps={{
              dataTestId: `${dataTestId}-delete-button`,
            }}
            entityCardDataTestId={`${dataTestId}-entity-card`}
            isRequired={isRequired}
          />
        </div>
      )}
    </Container>
  );
};

export default ContactInput;
