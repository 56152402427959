import { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import useForm from "../../../utils/hooks/useForm.ts";
import VERIFICATION_STATUS_QUERY from "../../../graphql/query/VerificationStatusQuery.graphql";
import JOIN_TEAM_MUTATION from "../../../graphql/mutation/JoinTeamMutation.graphql";

/*
DOCUMENTATION

useJoinTeam is designed to be used with the component JoinTeam.

args:
  client: (obj) required. this is an instance of the apolloClient needed for using useMutation outside of web/mobile context.
  handleJoinTeamSucceed: (func) required. Callback function that will be fired when join mutation succeed.
  handleVerificationStatusSucceed: (func) required. Callback function that will be fired when query succeed.
  verificationToken: (string) required. Token genereted by server that is being sent when receiving a join team email.

return:
  loading: (bool) loading state related to the verification query.
  fields: (obj) fields object returned by the useForm hook.
  onSubmit: (func) callback function that will fire the join team mutation.
  updateField: (func) callback function returned by the useForm hook.
  isVisitedForm: (bool) boolean state returned by the useForm hook.
  error: (string) error state.
*/
const useJoinTeam = ({
  client,
  handleJoinTeamSucceed,
  handleVerificationStatusSucceed,
  verificationToken,
  i18n,
}) => {
  const [error, setError] = useState("");

  const formConfig = {
    firstName: {
      value: "",
      validations: [
        {
          type: "required",
          errorMessage: i18n.t("signin-JoinTeam-firstName-error"),
        },
      ],
    },
    lastName: {
      value: "",
      validations: [
        {
          type: "required",
          errorMessage: i18n.t("signin-JoinTeam-lastName-error"),
        },
      ],
    },
    password: {
      value: "",
      validations: [
        { type: "required", errorMessage: i18n.t("signin-JoinTeam-required") },
        {
          type: "minLength",
          minLength: 8,
          errorMessage: i18n.t("signin-JoinTeam-minLength", { minLength: 8 }),
        },
        {
          type: "hasNumber",
          errorMessage: i18n.t("signin-JoinTeam-need1Number"),
        },
        {
          type: "hasUpperAndLowerCase",
          errorMessage: i18n.t("signin-JoinTeam-needLowerAndUpper"),
        },
      ],
    },
    passwordConfirm: {
      value: "",
      validations: [
        { type: "required", errorMessage: i18n.t("signin-JoinTeam-required") },
        {
          type: "minLength",
          minLength: 8,
          errorMessage: i18n.t("signin-JoinTeam-minLength", { minLength: 8 }),
        },
        {
          type: "hasNumber",
          errorMessage: i18n.t("signin-JoinTeam-need1Number"),
        },
        {
          type: "hasUpperAndLowerCase",
          errorMessage: i18n.t("signin-JoinTeam-needLowerAndUpper"),
        },
      ],
    },
  };

  const [joinTeamMutation] = useMutation(JOIN_TEAM_MUTATION, { client });
  const { loading, data: verificationStatus } = useQuery(
    VERIFICATION_STATUS_QUERY,
    {
      client,
      variables: { verificationToken },
    }
  );

  if (!loading && verificationStatus) {
    if (
      verificationStatus.status === null ||
      verificationStatus.status === "COMPLETE"
    ) {
      handleVerificationStatusSucceed();
    }
  }

  const { fields, onSubmit, updateField, isVisitedForm } = useForm(
    formConfig,
    async (fields) => {
      const { firstName, lastName, password } = fields;
      setError("");

      const {
        data: { joinTeam },
      } = await joinTeamMutation({
        variables: {
          input: {
            verificationToken,
            firstName: firstName.value,
            lastName: lastName.value,
            password: password.value,
          },
        },
      }).catch((err) => {
        console.warn(err);
        setError(i18n.t("signin-JoinTeam-error"));
      });

      if (joinTeam.session === null) {
        if (joinTeam.errors !== undefined) {
          console.warn("Could not join team.");
          setError(i18n.t("signin-JoinTeam-couldNotJoin"));
          return null;
        }
      }
      joinTeam.session.user.stockMessages =
        joinTeam.session.user?.stockMessages?.map((sm, i) => ({
          label: sm,
          key: i,
        }));
      return handleJoinTeamSucceed(joinTeam);
    }
  );

  return {
    loading,
    fields,
    onSubmit,
    updateField,
    isVisitedForm,
    error,
  };
};

export default useJoinTeam;
