import { useMutation } from "@apollo/client";
import useForm from "../../../../utils/hooks/useForm.ts";
import updateCustomerContactMutation from "../../../../graphql/mutation/UpdateCustomerContactMutation.graphql";
import updateCustomerAccountMutation from "../../../../graphql/mutation/UpdateCustomerAccountMutation.graphql";

/*
DOCUMENTATION:

useCustomerEmailEdit is designed to be used with the component CustomerEmailEdit. It provides email state values, a way to set the email state values,
email ref, as well as an onSubmit function that will validate to make sure the email value is valid before executing the email update mutation.

args:
  customer: (obj) required. this is a CustomerAccount object instance.
  onSave: (func) optional. a callback function the dev would like to run after the success of saving the customers email
  client: (obj) required. this is an instance of the apolloClient needed for using useMutation outside of web/mobile context

returns:
  [0] email: (obj) state for email value user will change when typing/ save when saves. to get email's value, use email.value, to set email's value, use email.setValue, to
  access a ref attached to the email state, use email.ref.
  [1] onSubmit: (func) fire when user "saves" the new value. This will execute the email value validation, and if it passes validations, it will execute the mutation
  necessary to update customer email.
*/

function useCustomerEmailEdit({ client, onSave, customer, i18n, onError }) {
  const [updateCustomer] = useMutation(
    customer.__typename === "CustomerAccount"
      ? updateCustomerAccountMutation
      : updateCustomerContactMutation,
    { client }
  );

  const inputFieldsConfig = {
    email: {
      value: customer.emailAddress || "",
      validations: [
        {
          type: "emailFormat",
          errorMessage: i18n.t("slideouts-validation-invalidEmail"),
        },
      ],
      optional: true,
    },
  };

  const sendMutation = async () => {
    const { data } = await updateCustomer({
      variables: {
        input: {
          id: customer.id,
          emailAddress: email.value,
        },
      },
    }).catch((err) => {
      onError?.();
      console.warn(err);
    });

    if (data?.updateCustomerContact?.errors) {
      const error = data?.updateCustomerContact?.errors[0]?.reason;
      if (error === "email_address_in_use") {
        onError?.(
          i18n.t("settings-ProfileFormContainer-emailDuplicateError", {
            defaultValue: "Email address is already in use",
          })
        );
      } else {
        onError?.();
      }
    } else {
      onSave(email?.value);
    }
  };

  const { fields, onSubmit } = useForm(inputFieldsConfig, sendMutation);
  const { email } = fields;

  return [email, onSubmit];
}

export default useCustomerEmailEdit;
