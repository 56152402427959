import { useMutation } from "@apollo/client";
import RESET_PASSWORD from "../../../graphql/mutation/ResetPasswordMutation.graphql";
import useAction from "../../../utils/hooks/useAction";
import useForm from "../../../utils/hooks/useForm.ts";

/*
DOCUMENTATION

useResetPassword is designed to be used with the component ResetPassword.

args:
  client: (obj) required. this is an instance of the apolloClient needed for using useMutation outside of web/mobile context.
  onResetPasswordSucceed: (func) required. Callback function that will be fired when reset password mutation succeed.
  resetToken: (string) required. Token genereted by server that is being sent when receiving a resetToken email.

return:
  fields: (obj) fields object returned by the useForm hook.
  onSubmit: (func) callback function that will fire the reset password mutation.
  updateField: (func) callback function returned by the useForm hook.
  isVisitedForm: (bool) boolean state returned by the useForm hook.
  error: (string) error state.
*/

const useResetPassword = ({
  client,
  onResetPasswordSucceed,
  resetToken,
  i18n,
}) => {
  const [resetPasswordMutation] = useMutation(RESET_PASSWORD, { client });
  const { error, setError } = useAction("");

  const formConfig = {
    password: {
      value: "",
      validations: [
        {
          type: "required",
          errorMessage: i18n.t("signin-ResetPassword-required"),
        },
        {
          type: "minLength",
          minLength: 8,
          errorMessage: i18n.t("signin-ResetPassword-minLength", {
            minLength: 8,
          }),
        },
        {
          type: "hasNumber",
          errorMessage: i18n.t("signin-ResetPassword-need1Number"),
        },
        {
          type: "hasUpperAndLowerCase",
          errorMessage: i18n.t("signin-ResetPassword-needLowerAndUpper"),
        },
      ],
    },
    passwordConfirm: {
      value: "",
      validations: [
        {
          type: "required",
          errorMessage: i18n.t("signin-ResetPassword-required"),
        },
        {
          type: "minLength",
          minLength: 8,
          errorMessage: i18n.t("signin-ResetPassword-minLength", {
            minLength: 8,
          }),
        },
        {
          type: "hasNumber",
          errorMessage: i18n.t("signin-ResetPassword-need1Number"),
        },
        {
          type: "hasUpperAndLowerCase",
          errorMessage: i18n.t("signin-ResetPassword-needLowerAndUpper"),
        },
      ],
    },
  };

  const { fields, onSubmit, updateField, isVisitedForm } = useForm(
    formConfig,
    async (formValues) => {
      const {
        data: { resetPassword },
      } = await resetPasswordMutation({
        variables: {
          input: {
            reset_token: resetToken,
            new_password: formValues.password.value,
          },
        },
      }).catch((err) => {
        console.warn(err);
        setError(i18n.t("signin-ResetPassword-error"));
      });
      if (resetPassword.session === null) {
        if (resetPassword.errors !== undefined) {
          console.warn("Could not reset password.");
          setError(resetPassword.errors[0].reason);
        }
      } else {
        onResetPasswordSucceed(resetPassword);
      }
    }
  );

  return {
    fields,
    onSubmit,
    updateField,
    isVisitedForm,
    error,
  };
};

export default useResetPassword;
