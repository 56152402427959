import React from 'react';
import { Avatar } from '../../elements';

const AppLogo = () => {
  const currentMonth = new Date().getMonth();
  if (currentMonth === 9) {
    return (
      <img
        src="/images/prokeep-pumpkin.png"
        alt="Prokeep Logo on a Halloween Pumpkin"
        height="45px"
        style={{ cursor: 'pointer' }}
      />
    );
  }
  if (currentMonth === 10 && process.env?.PK_ENV !== 'canada') {
    return (
      <img
        src="/images/prokeep-turkey.png"
        alt="Prokeep Logo as a Festive Turkey"
        height="45px"
        style={{ cursor: 'pointer' }}
      />
    );
  }
  return (
    <Avatar
      dataTestId="prokeep-logo-avatar"
      type="bot"
      customStyle={() => `&:hover{cursor: pointer;}`}
    />
  );
};

export default AppLogo;
