import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import i18n from 'i18n-js';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import GroupAsyncSelect from '../../../../AsyncSelects/GroupAsyncSelect';
import LabelAsyncSelect from '../../../../AsyncSelects/LabelAsyncSelect';
import { FilterBy } from '../../../../../elements';
import { SortBy } from '../../../../../lib/patterns/index.ts';

const FilterSortContainer = styled.div`
  display: flex;
  gap: 8px;
  div {
    margin-top: unset;
  }
`;

const getActiveFilterCount = (filters) => {
  const groupsActive = filters.groups?.length ? 1 : 0;
  const labelsActive = filters.labels?.length ? 1 : 0;
  return groupsActive + labelsActive;
};

const AudienceFilterSort = ({
  hasFilter,
  filters,
  handleSubmitFilter,
  handleSubmitSort,
  sortBy,
  sortOptions,
}) => {
  const [openFilter, setOpenFilter] = useState(false);
  const [groupValue, setGroupValue] = useState(filters.groups || []);
  const [labelValue, setLabelValue] = useState(filters.labels || []);
  const [activeFilters, setActiveFilters] = useState(
    getActiveFilterCount(filters)
  );
  const ff_contact_labels = useSelector(
    (state) => state.accountData.account?.ff_contact_labels
  );

  // Applies new sort direction
  const handleSort = (_, ind) => {
    handleSubmitSort(sortOptions[ind]);
  };

  // Sets selected groups
  const handleGroupSelect = (groups) => {
    setGroupValue(groups);
  };

  // Sets selected labels
  const handleLabelSelect = (labels) => {
    setLabelValue(labels);
  };

  // Resets filters to previous state and closes modal
  const cancelFilters = () => {
    setGroupValue(filters.groups || []);
    setLabelValue(filters.labels || []);
    setOpenFilter(false);
  };

  // Clears all filters
  const clearFilters = () => {
    setGroupValue([]);
    setLabelValue([]);
  };

  // Applies new selected filters
  const submitFilters = () => {
    const filters = { groups: groupValue, labels: labelValue };
    setActiveFilters(getActiveFilterCount(filters));
    setOpenFilter(false);
    handleSubmitFilter(filters);
  };

  return (
    <FilterSortContainer>
      {hasFilter && (
        <FilterBy
          disableClear={
            !(
              filters.groups?.length ||
              filters.labels?.length ||
              groupValue.length ||
              labelValue.length
            )
          }
          onSubmit={submitFilters}
          clearAllFilters={clearFilters}
          onCancel={cancelFilters}
          isOpen={openFilter}
          setIsOpen={() => setOpenFilter(true)}
          activeFiltersNum={activeFilters}
          dataTestId="create-broadcastList-filterBy"
          bubbleProps={{
            moveBubbleRightVal: 156,
            moveLeftVal: activeFilters ? -22 : -50,
            top: '20px',
            classesNotOutside: ['Select__clear-indicator'],
          }}
        >
          <GroupAsyncSelect
            omitAll
            dataTestId="create-broadcastList-groupSelector"
            value={groupValue}
            setValue={handleGroupSelect}
            label={i18n.t('settings-TeamMemberForm-groups', {
              defaultValue: 'Groups',
            })}
            placeholder={i18n.t('threads-Dashboard-selectGroups', {
              defaultValue: 'Select Groups',
            })}
            isMulti
          />
          {!!ff_contact_labels && (
            <LabelAsyncSelect
              dataTestId="create-broadcastList-labelSelector"
              value={labelValue}
              setValue={handleLabelSelect}
              label={i18n.t('settings-Label-contactLabels', {
                defaultValue: 'Contact Labels',
              })}
              placeholder={i18n.t('broadcasts-broadcastList-selectLabels', {
                defaultValue: 'Select Labels',
              })}
              isMulti
            />
          )}
        </FilterBy>
      )}
      <SortBy
        options={sortOptions}
        onSelect={handleSort}
        selection={sortBy}
        dataTestId="AudienceFilterSort"
      />
    </FilterSortContainer>
  );
};

AudienceFilterSort.propTypes = {
  filters: PropTypes.shape({
    groups: PropTypes.arrayOf(PropTypes.string),
    labels: PropTypes.arrayOf(PropTypes.string),
  }),
  handleSubmitFilter: PropTypes.func,
  handleSubmitSort: PropTypes.func,
  hasFilter: PropTypes.bool,
  sortBy: PropTypes.object,
  sortOptions: PropTypes.array,
};

AudienceFilterSort.defaultProps = {
  filters: {},
  handleSubmitFilter: () => {},
  handleSubmitSort: () => {},
  hasFilter: false,
  sortBy: {},
  sortOptions: [],
};

export default AudienceFilterSort;
