import React from 'react';
import PropTypes from 'prop-types';
import { TemplateGroupsWrapper } from './shared/styles.ts';
import { MeasuredBadge } from './shared/MeasuredBadge';
import { CutoffBadge } from './shared/CutoffBadge';
import { useWidthLimiter } from './shared/useWidthLimiter';

/**
 * MeasurableBadgeContainer is used for displaying a contact's groups in an inline badge format,
 * with a final +{{number}} badge to indicate how many badges were hidden for not fitting inside the container
 * @param {string} dataTestIds
 * @param {array} badgeLabels - group badges
 * @param {number} maxWidth - width of container, discovered by using useMeasure hook
 * @returns React component
 */
const MeasurableBadgeContainer = ({ dataTestIds, badgeLabels, maxWidth }) => {
  const { badgeCutoffNum, calculatingWidth, widthCallback } = useWidthLimiter(
    maxWidth,
    badgeLabels
  );

  const sortedBadgeLabels = badgeLabels.sort();
  return (
    <TemplateGroupsWrapper
      style={{ visibility: calculatingWidth ? 'hidden' : 'initial' }}
    >
      {sortedBadgeLabels.map((label, i) => (
        <MeasuredBadge
          key={i}
          dataTestId={dataTestIds}
          color={label.color || 'grey'}
          label={label}
          widthCallback={(badgeWidth) => widthCallback(badgeWidth, i)}
        />
      ))}
      {!!badgeCutoffNum && (
        <CutoffBadge
          displayNumber={(sortedBadgeLabels?.length ?? 0) - badgeCutoffNum}
        />
      )}
    </TemplateGroupsWrapper>
  );
};

MeasurableBadgeContainer.propTypes = {
  badgeLabels: PropTypes.array.isRequired,
  dataTestIds: PropTypes.string,
  maxWidth: PropTypes.number.isRequired,
};

MeasurableBadgeContainer.defaultProps = {
  dataTestIds: 'ts-group-badge',
};

export default MeasurableBadgeContainer;
