import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import THEMES from '../../styles/themes/app';
import EntityCard from './EntityCard';
import InputLabel from '../inputCommonElements/InputLabel.ts';
import FONTSIZE_THEMES from '../../styles/themes/fontSize/fontSize';

const cardStyle = (props) => `
  width: 100%;
  padding: ${props.subtext ? '3' : '0'}px 4px;
  border-radius: 4px;
  border-bottom: none;
  box-sizing: border-box;
  `;
const maintextStyle = (props) => `
  padding-top: ${props.subtext ? '0' : '11.5'}px;
  padding-bottom: ${props.subtext ? '0' : '11.5'}px;
  font-size: ${FONTSIZE_THEMES.SELECTEDENTITY_TEXT(props)}; 
  margin-bottom: 0;
`;
const subtextStyle = (props) => `
  font-size: ${FONTSIZE_THEMES.SELECTEDENTITY_SUBTEXT(props)};
`;
const textRecipientStyle = () => `
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Container = styled.div`
  width: 100%;
`;

const WhiteSpaceContainerBelow = styled.div`
  height: 32px;
`;

const SelectedEntity = ({
  avatarProps,
  hideBottomSpace,
  label,
  dataTestId,
  entityCardDataTestId,
  isRequired = false,
  ...props
}) => (
  <Container>
    {label && <InputLabel isRequired={isRequired}>{label}</InputLabel>}
    <EntityCard
      customContainerStyle={(prevProps) =>
        cardStyle({ ...prevProps, ...props })
      }
      customMaintextStyle={(prevProps) =>
        maintextStyle({ ...prevProps, ...props })
      }
      customSubtextStyle={subtextStyle}
      customTextContainerStyle={textRecipientStyle}
      backgroundColor={THEMES.BACKGROUND_SECONDARY}
      avatarProps={{
        size: 'md',
        ...avatarProps,
      }}
      entityCardDataTestId={entityCardDataTestId}
      {...props}
    />
    {!hideBottomSpace && <WhiteSpaceContainerBelow data-testid={dataTestId} />}
  </Container>
);

SelectedEntity.propTypes = {
  avatarProps: PropTypes.object,
  hideBottomSpace: PropTypes.bool,
  label: PropTypes.string,
  dataTestId: PropTypes.string,
  entityCardDataTestId: PropTypes.string,
  isRequired: PropTypes.bool,
};

SelectedEntity.defaultProps = {
  avatarProps: { size: 'md' },
  hideBottomSpace: false,
  label: null,
  dataTestId: null,
  entityCardDataTestId: null,
  isRequired: false,
};

export default SelectedEntity;
