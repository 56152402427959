import theme from 'styled-theming';
import COLORS from '../../colors';

const HOVER_STATE = theme('mode', {
  classic: COLORS.PK.GOLD_40,
  light: COLORS.PK.GOLD_40,
  dark: COLORS.PK_DARK.GREY_5,
  holiday: COLORS.PK.GREY_40,
});

const SELECTED_STATE = theme('mode', {
  classic: COLORS.PK.GREY_10,
  light: COLORS.PK.GREY_10,
  dark: COLORS.PK_DARK.GREY_4,
  holiday: COLORS.PK.GREY_50,
});

const THEMES = {
  HOVER_STATE,
  SELECTED_STATE,
};

export default THEMES;
