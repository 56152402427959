import { type Dispatch, type SetStateAction, useState, useEffect } from 'react';
import { isNil } from 'ramda';
import { containsNoValue } from '../helpers';
import { emailRegex, phoneRegex } from '../validation';
import { urlRegex } from '../regex';
import type { Validation } from '../helpers/types';
import type { FieldValue } from './helpers/types';

const checkCase =
  (errorMessage: string) =>
  (val: string, setError: Dispatch<SetStateAction<string>>) => {
    if (val.toUpperCase() === val || val.toLowerCase() === val) {
      setError(errorMessage);
      return false;
    }
    setError('');
    return true;
  };

const checkNumber =
  (errorMessage: string) =>
  (val: string, setError: Dispatch<SetStateAction<string>>) => {
    if (!/\d/.test(val)) {
      setError(errorMessage);
      return false;
    }
    setError('');
    return true;
  };

const maxIntSize =
  (errorMessage: string) =>
  (val: string, setError: Dispatch<SetStateAction<string>>) => {
    if (Number(val) >= 10000000) {
      setError(errorMessage);
      return false;
    }
    setError('');
    return true;
  };

const createRequired =
  (errorMessage: string) =>
  (val: string, setError: Dispatch<SetStateAction<string>>) => {
    if (isNil(val) || containsNoValue(val)) {
      setError(errorMessage);
      return false;
    }
    setError('');
    return true;
  };

const createMaxLength =
  (maxLength: number, errorMessage: string) =>
  (text: string, setError: Dispatch<SetStateAction<string>>) => {
    if (text.length > maxLength) {
      setError(errorMessage);
      return false;
    }
    setError('');
    return true;
  };

const createMinLength =
  (minLength: number, errorMessage: string) =>
  (text: string, setError: Dispatch<SetStateAction<string>>) => {
    if (text.length < minLength) {
      setError(errorMessage);
      return false;
    }
    setError('');
    return true;
  };

const createEmailFormat =
  (errorMessage: string) =>
  (val: string, setError: Dispatch<SetStateAction<string>>) => {
    if (!emailRegex.test(val)) {
      setError(errorMessage);
      return false;
    }
    setError('');
    return true;
  };

const createPhoneFormat =
  (errorMessage: string) =>
  (val: string, setError: Dispatch<SetStateAction<string>>) => {
    if (!phoneRegex.test(val)) {
      setError(errorMessage);
      return false;
    }

    setError('');
    return true;
  };

const testValidUrl =
  (errorMessage: string) =>
  (val: string, setError: Dispatch<SetStateAction<string>>) => {
    if (!urlRegex.test(val)) {
      setError(errorMessage);
      return false;
    }
    setError('');
    return true;
  };

/**
 * Retrieves the key names for the type and value properties from the first item in the fieldValueArray.
 * This allows us to use different key names for each field type as long as it follows a pattern.
 * Pattern: {fieldType}Type and {fieldType}Value
 *
 * @param {Array<Object>} fieldValueArray The array of field values.
 * @returns {{ type: string | null, value: string | null }} An object containing the key names for the type and value properties.
 */
const getFieldKeys = (fieldValueArray: FieldValue[]) => {
  const keysTypeValue: {
    type: string | null;
    value: string | null;
  } = {
    type: null,
    value: null,
  };
  const firstItem = fieldValueArray?.[0];

  if (!firstItem) {
    return keysTypeValue;
  }

  Object.keys(firstItem).forEach((key) => {
    if (key.endsWith('Type')) {
      keysTypeValue.type = key;
    } else if (key.endsWith('Value')) {
      keysTypeValue.value = key;
    }
  });

  return keysTypeValue;
};

const handleMultipleFields =
  (errorMessage: string) =>
  (val: FieldValue[], setError: Dispatch<SetStateAction<unknown>>) => {
    const fieldKeys = getFieldKeys(val);

    if (!fieldKeys.type || !fieldKeys.value) {
      setError({});
      return true;
    }

    const errors = val.reduce(
      (acc: { [key: string]: string }, item: FieldValue) => {
        if (
          containsNoValue(item[fieldKeys.value as keyof FieldValue] as string)
        ) {
          const fieldType =
            (item[fieldKeys.type as keyof FieldValue] as string) || 'unknown';
          if (!item.isTypeOnly) {
            acc[fieldType] = errorMessage;
          }
        }
        return acc;
      },
      {},
    );

    if (Object.keys(errors).length !== 0) {
      setError(errors);
      return false;
    }

    setError({});
    return true;
  };

const useValidatedInput = (
  initialValue: string & FieldValue[],
  initialOtherProps?: object,
  validationTypes: Validation[] = [],
  optional: boolean = false,
) => {
  const [text, setText] = useState(initialValue);
  const [otherProps, setOtherProps] = useState(initialOtherProps);
  const [error, setError] = useState<unknown>('');
  const [focus, setFocus] = useState(false);

  const validationTypeToMethod = (type: Validation) => {
    switch (type.type) {
      case 'required':
        return createRequired(type.errorMessage);

      case 'maxLength':
        return createMaxLength(type.maxLength as number, type.errorMessage);

      case 'minLength':
        return createMinLength(type.minLength as number, type.errorMessage);

      case 'emailFormat':
        return createEmailFormat(type.errorMessage);

      case 'phoneFormat':
        return createPhoneFormat(type.errorMessage);

      case 'hasNumber':
        return checkNumber(type.errorMessage);

      case 'maxIntSize':
        return maxIntSize(type.errorMessage);

      case 'hasUpperAndLowerCase':
        return checkCase(type.errorMessage);

      case 'urlFormat':
        return testValidUrl(type.errorMessage);

      case 'multipleFields':
        return handleMultipleFields(type.errorMessage);

      default:
        return () => true;
    }
  };

  const validationMethods = validationTypes.map((type) =>
    validationTypeToMethod(type),
  );

  const validate = () => {
    if (optional && text === '') {
      setError('');
      return true;
    }

    const valid = validationMethods.reduce((isValid, method) => {
      if (!isValid) return isValid;
      return method(text, setError);
    }, true);

    return valid;
  };

  useEffect(() => {
    validate();
  }, [text]);

  return [
    text,
    setText,
    error,
    validate,
    otherProps,
    setOtherProps,
    focus,
    setFocus,
    setError,
  ];
};

export default useValidatedInput;
