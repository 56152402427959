import { useState } from "react";
import { useMutation } from "@apollo/client";
import SIGN_IN_MUTATION from "../../../graphql/mutation/SignInMutation.graphql";
import SIGN_IN_OAUTH_MUTATION from "../../../graphql/mutation/SignInOauthMutation.graphql";
import useForm from "../../../utils/hooks/useForm.ts";
import useAction from "../../../utils/hooks/useAction";

const ERROR_TYPES = {
  USER_NOT_FOUND: "user_not_found",
  USER_DISABLED: "user_disabled",
  INVALID_PASSWORD: "invalid_password",
};

/*

DOCUMENTATION
  this hook was created to be used with SignIn. handles input values, validation, mutation, and any errors that may arise

  args:
    handleSignInSuccess: (func) required. Callback function when mutation succeed
    client: (obj) required. Apollo Client instance
    i18n: (func) required. i18n function from react-i18next
    url: (str) optional. Url to be used in fetch request
    oauth: (bool) optional. If true, will use SIGN_IN_OAUTH_MUTATION, otherwise SIGN_IN_MUTATION
    Sentry: (obj) optional. Sentry instance

  returns:
    fields: (obj) fields object returned by the useForm hook.
    onSubmit: (func) callback function that will fire the sign in team mutation.
    updateField: (func) callback function returned by the useForm hook.
    isVisitedForm: (bool) boolean state returned by the useForm hook.
    signInError: (string) states general error
    handlePasswordToggle: (func) callback function to update password type
    passwordFieldType: (str) state, password type value. Two values => password/text
*/

const useSignIn = ({
  handleSignInSuccess,
  client,
  i18n,
  url = null,
  oauth = false,
  Sentry = null,
}) => {
  const [signInMutation] = useMutation(
    oauth ? SIGN_IN_OAUTH_MUTATION : SIGN_IN_MUTATION,
    { client, fetchPolicy: "no-cache" }
  );
  const [passwordFieldType, setPasswordFieldType] = useState("password");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const baseUrl = url ?? "";

  const formConfig = {
    username: {
      value: "",
      validations: [
        {
          type: "required",
          errorMessage: i18n.t("signin-SignIn-emailAddressValidation", {
            defaultValue: "Email address is required.",
          }),
        },
        {
          type: "emailFormat",
          errorMessage: i18n.t("signin-SignIn-emailAddressInvalid", {
            defaultValue: "Invalid email address.",
          }),
        },
      ],
    },
    password: {
      value: "",
      validations: [
        {
          type: "required",
          errorMessage: i18n.t("signin-SignIn-passwordRequired", {
            defaultValue: "Password Required.",
          }),
        },
      ],
    },
  };

  const { error, setError } = useAction();

  const handleSignIn = async (values) => {
    // extrapolated and made available for Steven's SSO purposes, as he wants to bypass the form.
    setIsSubmitting(true);
    await fetch(`${baseUrl}/cookie?type=domain`, { method: "DELETE" });
    await fetch(`${baseUrl}/cookie?type=subdomain`, { method: "DELETE" });

    const input = {
      username: values.username.value,
    };

    if (oauth) {
      input.token = values.token.value;
    } else {
      input.password = values.password.value;
    }

    const response = await signInMutation({
      variables: {
        input,
      },
    }).catch((err) => {
      setError(
        err.message ||
          i18n.t("signin-SignIn-loginFailed", {
            defaultValue: "Login failed.",
          })
      );

      Sentry?.captureMessage("useSignIn: signInMutation error", {
        extra: {
          url: baseUrl,
          oauth,
          username: values.username.value,
          error: err,
        },
        level: "error",
      });
    });

    const propertyName = oauth ? "signInOauth" : "signIn";

    if (response?.data?.[propertyName]?.errors) {
      const errorReason = response.data[propertyName].errors[0].reason;
      if (
        errorReason === ERROR_TYPES.INVALID_PASSWORD ||
        errorReason === ERROR_TYPES.USER_NOT_FOUND ||
        errorReason === ERROR_TYPES.USER_DISABLED
      ) {
        setError(
          i18n.t("signin-SignIn-noMatchRecords", {
            defaultValue:
              "The information you provided does not match our records",
          })
        );

        Sentry?.captureMessage("useSignIn: noMatchRecords error", {
          extra: {
            url: baseUrl,
            oauth,
            username: values.username.value,
            error: response.data[propertyName].errors[0],
          },
          level: "error",
        });
      } else if (propertyName === "signInOauth") {
        Sentry?.captureMessage("useSignIn: signInOauth error", {
          extra: {
            url: baseUrl,
            oauth,
            username: values.username.value,
            error: response.data[propertyName].errors[0],
          },
          level: "error",
        });
      }
      setIsSubmitting(false);
    } else if (response?.data?.[propertyName]?.session) {
      handleSignInSuccess(response.data[propertyName].session);
    }
  };

  const { fields, onSubmit, updateField, isVisitedForm } = useForm(
    formConfig,
    handleSignIn
  );

  const handleOnKeyPress = (ev) => {
    if (ev.key === "Enter") {
      onSubmit();
    }
  };

  const handlePasswordToggle = () => {
    if (passwordFieldType === "password") {
      setPasswordFieldType("text");
    } else {
      setPasswordFieldType("password");
    }
  };

  return {
    fields,
    onSubmit,
    updateField,
    isVisitedForm,
    handlePasswordToggle,
    passwordFieldType,
    signInError: error,
    isSubmitting,
    setIsSubmitting,
    handleOnKeyPress,
    handleSignIn, // extrapolated and made available for Steven's SSO purposes, as he wants to bypass the form. Do not use for standard password signin only oauth/token-only signIn.
  };
};

export default useSignIn;
