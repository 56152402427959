import theme from 'styled-theming';
import COLORS from '../colors';

// generic colors
const BORDER_COLOR = theme('mode', {
  classic: COLORS.PK_CLASSIC.SECONDARY_GRAY,
  light: COLORS.PK.GREY_20,
  dark: COLORS.PK_DARK.GREY_4,
  holiday: COLORS.PK.GREY_20,
});

const BOX_SHADOW = theme('mode', {
  classic: '0 1px 4px 0 rgba(0,0,0,0.15)',
  light: '0 1px 4px 0 rgba(0,0,0,0.15)',
  dark: '0 1px 4px 0 rgba(0,0,0,0.15)',
  holiday: '0 1px 4px 0 rgba(0,0,0,0.15)',
});

// neutral backgrounds & designated forgrounds
const BACKGROUND_PRIMARY = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_WHITE,
  light: COLORS.PK.WHITE,
  dark: COLORS.PK_DARK.BLACK,
  holiday: COLORS.PK.WHITE,
});

const BACKGROUND_SECONDARY = theme('mode', {
  classic: COLORS.PK_CLASSIC.LIGHT_GRAY,
  light: COLORS.PK.GREY_10,
  dark: COLORS.PK_DARK.GREY_5,
  holiday: COLORS.PK.GREY_10,
});

const BACKGROUND_TERTIARY = theme('mode', {
  classic: COLORS.PK_CLASSIC.SECONDARY_GRAY,
  light: COLORS.PK.GREY_20,
  dark: COLORS.PK_DARK.GREY_4,
  holiday: COLORS.PK.GREY_20,
});

const BACKGROUND_QUATERNARY = theme('mode', {
  classic: COLORS.PK.NEUTRAL_GREY,
  light: COLORS.PK.NEUTRAL_GREY,
  dark: COLORS.PK_DARK.GREY_6,
  holiday: COLORS.PK.NEUTRAL_GREY,
});

const FOREGROUND_HIGH = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_BLACK,
  light: COLORS.PK.GREY_90,
  dark: COLORS.PK_DARK.TRANSPARENT_WHITE_HIGH,
  holiday: COLORS.PK.GREY_90,
});

const FOREGROUND_MED = theme('mode', {
  classic: COLORS.PK_CLASSIC.SECONDARY_BLACK,
  light: COLORS.PK.GREY_80,
  dark: COLORS.PK_DARK.TRANSPARENT_WHITE_MED,
  holiday: COLORS.PK.GREY_70,
});

const FOREGROUND_LOW = theme('mode', {
  classic: COLORS.PK_CLASSIC.DARK_GRAY,
  light: COLORS.PK.GREY_70,
  dark: COLORS.PK_DARK.TRANSPARENT_WHITE_LOW,
  holiday: COLORS.PK.GREY_50,
});

const CREATE_ANNOUNCEMENT_NEUTRAL = theme('mode', {
  classic: COLORS.PK_CLASSIC.SUPER_LIGHT_GRAY,
  light: COLORS.PK.GOLD_20,
  dark: COLORS.PK_DARK.GREY_4,
  holiday: COLORS.PK.GOLD_20,
});

// to be used in conjunction with BACKGROUND_PRIMARY, usually
const FOREGROUND_HIGH_COLOR = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_BLUE,
  light: COLORS.PK.BLUE,
  dark: COLORS.PK.BLUE_60,
  holiday: COLORS.PK_HOLIDAY.HOLIDAY_SECONDARY,
});

const FOREGROUND_MED_COLOR = theme('mode', {
  classic: COLORS.PK_CLASSIC.LIGHT_BLUE,
  light: COLORS.PK.BLUE_60,
  dark: COLORS.PK.BLUE_60,
  holiday: COLORS.PK_HOLIDAY.HOLIDAY_ACCENT_2,
  // holiday: COLORS.PK_HOLIDAY.HOLIDAY_TERTIARY,
});

// colored backgrounds and designated forgrounds
const COLORED_BACKGROUND_PRIMARY = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_NAVY,
  light: COLORS.PK.BLUE_100,
  dark: COLORS.PK.BLUE_100,
  holiday: COLORS.PK_HOLIDAY.HOLIDAY_PRIMARY,
});

const COLORED_BACKGROUND_SECONDARY = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_BLUE,
  light: COLORS.PK.BLUE_80,
  dark: COLORS.PK.BLUE_80,
  holiday: COLORS.PK_HOLIDAY.HOLIDAY_SECONDARY,
});

const COLORED_BACKGROUND_TERTIARY = theme('mode', {
  classic: COLORS.PK_CLASSIC.LIGHT_BLUE,
  light: COLORS.PK.BLUR,
  dark: COLORS.PK.BLUR,
  holiday: COLORS.PK_HOLIDAY.HOLIDAY_TERTIARY,
});

const COLORED_FOREGROUND_HIGH = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_WHITE,
  light: COLORS.PK.WHITE,
  dark: COLORS.PK.WHITE,
  holiday: COLORS.PK.WHITE,
});

const COLORED_FOREGROUND_MED = theme('mode', {
  classic: COLORS.PK_CLASSIC.LIGHT_GRAY,
  light: COLORS.PK.GOLD_20,
  dark: COLORS.PK.GOLD_20,
  holiday: COLORS.PK.GOLD_20,
});

const COLORED_FOREGROUND_LOW = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_GRAY,
  light: COLORS.PK.GOLD,
  dark: COLORS.PK.GOLD,
  holiday: COLORS.PK.GOLD,
});

const THEME_GREEN = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_GREEN,
  light: COLORS.PK.GREEN_80,
  dark: COLORS.PK.GREEN_80,
  holiday: COLORS.PK.GREEN_80,
});

const THEME_BACKGROUND_GREEN = theme('mode', {
  classic: COLORS.PK_CLASSIC.TERTIARY_GREEN,
  light: COLORS.PK.GREEN_40,
  dark: COLORS.PK.GREEN_40,
  holiday: COLORS.PK.GREEN_40,
});

const THEME_RED = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_RED,
  light: COLORS.PK.RED,
  dark: COLORS.PK.RED,
  holiday: COLORS.PK.RED,
});

/**
 * Represents the accessibility red theme color when used over BACKGROUND_PRIMARY.
 *
 * @type {string}
 */
const RED_PRIMARY = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_RED,
  light: COLORS.PK.RED_80,
  dark: COLORS.PK_DARK.RED,
  holiday: COLORS.PK.RED,
});

/**
 * Represents the accessibility red theme color when used over BACKGROUND_QUATERNARY.
 *
 * @type {string}
 */
const RED_QUATERNARY = theme('mode', {
  classic: COLORS.PK.RED_80,
  light: COLORS.PK.RED_80,
  dark: COLORS.PK.RED_60,
  holiday: COLORS.PK.RED_80,
});

const THEME_YELLOW = theme('mode', {
  classic: COLORS.PK_CLASSIC.WARNING_YELLOW,
  light: COLORS.PK.GOLD_100,
  dark: COLORS.PK.GOLD_100,
  holiday: COLORS.PK.GOLD_100,
});

// sidebar particulars
const SIDEBAR_BACKGROUND = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_NAVY,
  light: COLORS.PK.BLUE_100,
  dark: COLORS.PK_DARK.BLACK,
  holiday: COLORS.PK_HOLIDAY.HOLIDAY_PRIMARY,
});

const SIDEBAR_ICON = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_GRAY,
  light: COLORS.PK.GOLD_40,
  dark: COLORS.PK_DARK.TRANSPARENT_WHITE_HIGH,
  holiday: COLORS.PK.GOLD_40,
});

const SIDEBAR_ICON_DISABLED = theme('mode', {
  classic: COLORS.PK_CLASSIC.TERTIARY_GREEN,
  light: COLORS.PK.BLUE_80,
  dark: COLORS.PK.BLUE_80,
  holiday: COLORS.PK_HOLIDAY.HOLIDAY_ACCENT_3,
});

const SIDEBAR_ICON_ACTIVE = theme('mode', {
  classic: COLORS.PK_CLASSIC.TERTIARY_GREEN,
  light: COLORS.PK.GOLD_40,
  dark: COLORS.PK.BLUE_60,
  holiday: COLORS.PK.GOLD_40,
});

const SIDEBAR_ICON_ACTIVE_BACKGROUND = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_NAVY,
  light: COLORS.PK.BLUE_80,
  dark: COLORS.PK_DARK.GREY_4,
  holiday: COLORS.PK_HOLIDAY.HOLIDAY_SECONDARY,
});

const SIDEBAR_CREATE_BUTTON = theme('mode', {
  classic: COLORS.PK_CLASSIC.LIGHT_BLUE,
  light: COLORS.PK.BLUE_60,
  dark: COLORS.PK.BLUE_60,
  holiday: COLORS.PK_HOLIDAY.HOLIDAY_ACCENT,
});

const SIDEBAR_CREATE_BUTTON_HOVER = theme('mode', {
  classic: COLORS.PK_CLASSIC.LIGHT_BLUE_HOVER,
  light: COLORS.PK.BLUE_40,
  dark: COLORS.PK.BLUE_40,
  holiday: COLORS.PK_HOLIDAY.HOLIDAY_ACCENT,
});

// create sidebar
const CREATE_SIDEBAR = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_GREEN,
  light: COLORS.PK.BLUR,
  dark: COLORS.PK.BLUR,
  holiday: COLORS.PK_HOLIDAY.HOLIDAY_ACCENT_2,
});

const CREATE_SIDEBAR_ICON_ACTIVE_BACKGROUND = theme('mode', {
  classic: COLORS.PK_CLASSIC.SECONDARY_GREEN,
  light: COLORS.PK.BLUE_80,
  dark: COLORS.PK.BLUE_80,
  holiday: COLORS.PK_HOLIDAY.HOLIDAY_PRIMARY,
});

const CREATE_SIDEBAR_ICON_ACTIVE = theme('mode', {
  classic: COLORS.PK_CLASSIC.LIGHT_BLUE,
  light: COLORS.PK.GOLD_40,
  dark: COLORS.PK_DARK.TRANSPARENT_WHITE_MED,
  holiday: COLORS.PK.GOLD_40,
});

const ELLIPSIS_THREAD_HEADER = theme('mode', {
  classic: COLORS.PK_CLASSIC.LIGHT_BLUE,
  light: COLORS.PK.GOLD_40,
  dark: COLORS.PK_DARK.GREY_4,
  holiday: COLORS.PK.GOLD_40,
});

// logo colors
const LOGO_PRIMARY = theme('mode', {
  classic: COLORS.PK_CLASSIC.LIGHT_BLUE,
  light: COLORS.PK.LOGO_PRIMARY,
  dark: COLORS.PK.LOGO_PRIMARY,
  holiday: COLORS.PK_HOLIDAY.HOLIDAY_TERTIARY,
});

const LOGO_SECONDARY = theme('mode', {
  classic: COLORS.PK_CLASSIC.LOGO_GREEN,
  light: COLORS.PK.LOGO_SECONDARY,
  dark: COLORS.PK.LOGO_SECONDARY,
  holiday: COLORS.PK_HOLIDAY.HOLIDAY_PRIMARY,
});

// custom avatar color scheme for email avatar
const OVERLAY_AVATAR_PRIMARY = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_WHITE,
  light: COLORS.PK.WHITE,
  dark: COLORS.PK_CLASSIC.PRIMARY_WHITE,
  holiday: COLORS.PK.WHITE,
});

const OVERLAY_AVATAR_BACKGROUND = theme('mode', {
  classic: COLORS.PK_CLASSIC.SECONDARY_BLACK,
  light: COLORS.PK.GREY_80,
  dark: COLORS.PK_CLASSIC.SECONDARY_BLACK,
  holiday: COLORS.PK.GREY_70,
});

// PCI Breach Warning color
const PCI_BREACH_THEME = theme('mode', {
  classic: COLORS.PK.RED_80,
  light: COLORS.PK.RED_80,
  dark: COLORS.PK.RED_60,
  holiday: COLORS.PK.RED_80,
});

const WARNING_BACKGROUND = theme('mode', {
  classic: COLORS.PK.GOLD_100,
  light: COLORS.PK.GOLD_100,
  dark: COLORS.PK.GOLD_100,
  holiday: COLORS.PK.GOLD_100,
});

const WARNING_FOREGROUND_HIGH = theme('mode', {
  classic: COLORS.PK.GREY_90,
  light: COLORS.PK.GREY_90,
  dark: COLORS.PK.GREY_90,
  holiday: COLORS.PK.GREY_90,
});

const RICHTEXTEDITOR_DARKMODE = theme('mode', {
  classic: COLORS.PK.GREY_90,
  light: COLORS.PK.GREY_90,
  dark: COLORS.PK.GREY_90,
  holiday: COLORS.PK.GREY_90,
});

const THEMES = {
  BACKGROUND_PRIMARY,
  BACKGROUND_SECONDARY,
  BACKGROUND_TERTIARY,
  BACKGROUND_QUATERNARY,
  FOREGROUND_HIGH,
  FOREGROUND_MED,
  FOREGROUND_LOW,
  FOREGROUND_HIGH_COLOR,
  FOREGROUND_MED_COLOR,
  CREATE_ANNOUNCEMENT_NEUTRAL,

  COLORED_BACKGROUND_PRIMARY,
  COLORED_BACKGROUND_SECONDARY,
  COLORED_BACKGROUND_TERTIARY,
  COLORED_FOREGROUND_HIGH,
  COLORED_FOREGROUND_MED,
  COLORED_FOREGROUND_LOW,

  BORDER_COLOR,
  BOX_SHADOW,

  THEME_GREEN,
  THEME_BACKGROUND_GREEN,
  THEME_RED,
  RED_PRIMARY,
  RED_QUATERNARY,
  THEME_YELLOW,

  SIDEBAR_BACKGROUND,
  SIDEBAR_ICON,
  SIDEBAR_ICON_DISABLED,
  SIDEBAR_ICON_ACTIVE,
  SIDEBAR_CREATE_BUTTON,
  SIDEBAR_CREATE_BUTTON_HOVER,
  SIDEBAR_ICON_ACTIVE_BACKGROUND,
  CREATE_SIDEBAR,
  CREATE_SIDEBAR_ICON_ACTIVE,
  CREATE_SIDEBAR_ICON_ACTIVE_BACKGROUND,
  ELLIPSIS_THREAD_HEADER,
  LOGO_PRIMARY,
  LOGO_SECONDARY,

  OVERLAY_AVATAR_BACKGROUND,
  OVERLAY_AVATAR_PRIMARY,

  PCI_BREACH_THEME,
  WARNING_BACKGROUND,
  WARNING_FOREGROUND_HIGH,
  RICHTEXTEDITOR_DARKMODE,
};

export default THEMES;
